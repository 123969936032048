import React, { useEffect,useRef } from 'react';
import MessageListItem from '../messagelist-item/messagelist-item.component';
import './messagelist.styles.scss';

const MessageList = ({data,luid}) => {
  const messagesEndRef = useRef(null)

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" })
  }

  useEffect(scrollToBottom, [data]);
  return(
    <div className="messageList" >
      {
        data !== null ?
          data.length > 0 &&
            <div>
              {
                data.map((item,index)=>{
                  return(
                    <MessageListItem 
                      key={index}
                      loginUserId={luid}
                      toId={item.TOID}
                      fromId={item.FROMID}
                      message={item.MESSAGE}
                      sendtime={item.SENDDATETIME}
                      sender={item.SENDER}
                      receiver={item.receiver}
                    />
                  )
                })
              }
            </div>
        :
        <div className="messageLoader"></div>
      }
      <div ref={messagesEndRef} />
    </div>
  );

}

export default MessageList;