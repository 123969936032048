import React, { useState, useEffect,useRef,useLayoutEffect } from 'react';
//import {useSelector} from "react-redux";
import { Link, useHistory } from 'react-router-dom';
import { project } from '../../../utils/project';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { HashLink } from 'react-router-hash-link';
import axios from 'axios';
import { customShowLoadingLayer, customHideLoadingLayer, showWarningLayer, hideWarningLayer } from '../../../utils/utils';
import * as actionCreators from '../../../redux/login/login.actions';
import * as actionDefinitionCreators from '../../../redux/definitions/definitions.actions';
import { ProjectActionTypes } from '../../../redux/project/project.types';
import { LoginActionTypes } from '../../../redux/login/login.types';
import LoginPopup from '../../loginpopup/loginpopup.component';
import ChatPopup from '../../chatpopup/chatpopup.component';
import Menu from '../menu/menu.component';

import katilim from '../../../assets/images/katilim.svg';
import bilgilerim from '../../../assets/images/bilgilerim.svg';
import './navbar.styles.scss';


const NavBar = ({ menutype }) => {
  const stickyHeader = useRef();
  const xprops = useSelector(state => state);
  const dispatch = useDispatch();
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const handleOpenLogin = () => dispatch(actionCreators.setFormLoginStatus(true));

  const openChatWindow = () => dispatch(actionDefinitionCreators.setFormChatStatus(true));

  const logOut = () => dispatch(actionCreators.userLogout());
  const onWarning = (payload) => {
    dispatch({ type: ProjectActionTypes.SET_WARNING_MESSAGE, payload: payload });
  }

  const handleLogout = (event) => {
    customShowLoadingLayer();
    onWarning({ warningMessageType: 'success', warningMessageText: 'Çıkış yapıldı.' });
    showWarningLayer();
    setTimeout(() => {
      logOut();
      customHideLoadingLayer();
      hideWarningLayer();
    }, 3000);
  }




  useEffect(() => {
  }, []);

  useLayoutEffect(() => {
    const mainHeader = document.getElementById('navID');
    let fixedTop = stickyHeader.current.offsetTop;
    const fixedHeader = () => {
      if (window.pageYOffset > fixedTop + 200) {
        mainHeader.classList.add('navbarsticky');
      } else {
        mainHeader.classList.remove('navbarsticky');
      }
    }
    window.addEventListener('scroll', fixedHeader);
  }, [])  


  return (
    <React.Fragment>
      <nav id="navID" className="navbarhome" ref={stickyHeader}>

        <div className="navbarhome-container">
          <Link to="/" className="logoContainer">
            <img className="navbarhome-logo" src={process.env.PUBLIC_URL + '/assets/images/logo.png'} alt={project.projectName} />
          </Link>
          <div className="menu-icon" onClick={handleClick}>
            <i className={click ? 'fas' : 'fas fa-bars'} />
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>

            {
              click &&
              <button className="navmenuCloseBtn" onClick={closeMobileMenu}></button>
            }

            <li className="nav-item-main">
              <HashLink
                to="#navID"
                className='nav-links-main'
                onClick={closeMobileMenu}
              >
                UZMAN BUL
              </HashLink>
            </li>
            <li className="nav-item-main">
              <HashLink
                to='#hakkindaID'
                className='nav-links-main'
                onClick={closeMobileMenu}
              >
                HAKKINDA
              </HashLink>
            </li>
            <li className="nav-item-main">
              <HashLink
                to='#makalelerID'
                className='nav-links-main'
                onClick={closeMobileMenu}
              >
                BLOG
              </HashLink>
            </li>
            <li className="nav-item-main">
              <HashLink
                to='#egitimlerID'
                className='nav-links-main'
                onClick={closeMobileMenu}
              >
                EĞİTİMLER
              </HashLink>
            </li>
            <li className="nav-item-main">
              <HashLink
                to='#bizkimizID'
                className='nav-links-main'
                onClick={closeMobileMenu}
              >
                BİZ KİMİZ
              </HashLink>
            </li>
            <li className="nav-item-main">
              <HashLink
                to='#iletisimID'
                className='nav-links-main'
                onClick={closeMobileMenu}
              >
                İLETİŞİM
              </HashLink>
            </li>
            {
              xprops.login.isLoggedIn === true ?
                <React.Fragment>
                  <li className="nav-item-main">
                    <Link
                      onClick={(event) => { event.preventDefault(); openChatWindow() }}
                      className='nav-links-main'
                    >
                      MESAJLAR
                    </Link>
                  </li>
                  <li className="nav-item-main">
                    <Link
                      to='/profil'
                      className='nav-links-main'
                      onClick={closeMobileMenu}
                    >
                      BİLGİLERİM
                    </Link>
                  </li>
                </React.Fragment>
                :
                <React.Fragment>
                  <li className="nav-item-main">
                    <Link
                      to='/signup'
                      className='nav-links-main'
                      onClick={closeMobileMenu}
                    >
                      KATILIM
                    </Link>
                  </li>
                  <React.Fragment>
                    <li className="nav-item-main" onClick={handleOpenLogin}>
                      <span className="nav-links-main">GİRİŞ</span>
                    </li>
                  </React.Fragment>
                </React.Fragment>
            }

            {
              xprops.login.isLoggedIn === true &&
              <React.Fragment>
                <li className="nav-item-main" onClick={handleLogout}>
                  <span className="nav-links-main">ÇIKIŞ</span>
                </li>
              </React.Fragment>
            }


          </ul>
          <div className="mainMenuWrapper">
            <Menu />  
          </div>
          <ul className={click ? 'nav-menu-sub active' : 'nav-menu-sub'}>
          {
            xprops.login.isLoggedIn === true ?
              <React.Fragment>
                <li className="nav-item-sub linkToLogin" onClick={() => openChatWindow()}><img className="img-mesajlar" src={process.env.PUBLIC_URL + '/assets/images/messages-on.png'} alt={xprops.project.language.keywords.messages} /></li>
                <li className="nav-item-sub linka linkToLogin" onClick={() => openChatWindow()}>{xprops.project.language.keywords.messages}</li>

                <li className="nav-item-sub"><Link to='/profil'><img className="img-bilgilerim" src={bilgilerim} alt={xprops.project.language.keywords.profile} /></Link></li>
                <li className="nav-item-sub"><Link to='/profil' className="linka">{xprops.project.language.keywords.profile}</Link></li>
              </React.Fragment>
              :
              <React.Fragment>
                <li className="nav-item-sub linkToLogin" onClick={() => handleOpenLogin()}><img className="img-bilgilerim" src={bilgilerim} alt={xprops.project.language.keywords.profile} /></li>
                <li className="nav-item-sub linkToLogin" onClick={() => handleOpenLogin()}>{xprops.project.language.keywords.signIn}</li>
                {
                  xprops.login.isLoggedIn === true ?
                   <React.Fragment>
                    <li className="nav-item-sub-right">{xprops.login.username} Hoşgeldiniz! | <button className="btnSignout" onClick={handleLogout}>Çıkış</button></li>           
                   </React.Fragment>
                  :
                  <React.Fragment>
                    <li className="nav-item-sub-right"><Link to='/signup'><img className="img-katilim" src={katilim} alt={xprops.project.language.keywords.signUp} /></Link></li>
                    <li className="nav-item-sub-right"><Link to='/signup' className="linka" >{xprops.project.language.keywords.signUp}</Link></li>
                  </React.Fragment>
                }                
              </React.Fragment>
          }
          <li className="nav-item-sub langbg ">EN</li>
        </ul>          
        </div>

              
      </nav>
      <div className="menu-icon" onClick={handleClick}>
        <i className={click ? 'fas' : 'fas fa-bars'} />
      </div>

      <LoginPopup formOpenStatus={xprops.login.formLoginOpenStatus} loginFormType="kadinuzman" />

      {
        xprops.login.isLoggedIn === true &&
        <ChatPopup fullname="" />
      }

    </React.Fragment>
  )
};


export default NavBar;

