import React from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import NavBarSub from '../navbarsub/navbarsub.component';
// get our fontawesome imports

import './header.styles.scss';

class Header extends React.Component{

  constructor(props){
    super(props);
    this.state = {};
  }

  goTo = (event,url) => {
    event.preventDefault();
    return this.props.history.push('/'+url);    
  }

  componentDidMount(){

  }  

  render(){
    return(
      <React.Fragment>
        <NavBarSub />
        <header className={`topHeader ${this.props.currentPage === "baslangic" ? "headerBaslangic" : ""}`}>
        <div className="headerContentWrapper">
          
          {
            this.props.currentPage !== "baslangic" ?
            this.props.currentPage !== "success" &&
              <React.Fragment>
              <h2 className="webSectionTitle">Katılımcı Bilgi Formu</h2>          
              <nav className="navbar">
                <div className={this.props.currentPage === 'step-1' ? 'nav-active' : ''}>
                  <Link 
                    to="/signup/step-1"
                    className='nav-links'
                    
                  >
                    1.Adım
                  </Link>              
                </div>
                <div className={this.props.currentPage === 'step-2' ? 'nav-active' : ''}>
                  <Link 
                    to="/signup/step-2"
                    className='nav-links'
                    
                  >
                    2.Adım
                  </Link> 
                </div>
                <div className={this.props.currentPage === 'step-3' ? 'nav-active' : ''}>
                  <Link 
                    to="/signup/step-3"
                    className='nav-links'
                    
                  >
                    3.Adım
                  </Link> 
                </div>
                <div className={this.props.currentPage === 'step-4' ? 'nav-active' : ''}>
                  <Link 
                    to="/signup/step-4"
                    className='nav-links'
                    
                  >
                    4.Adım
                  </Link> 
                </div>
                <div className={this.props.currentPage === 'step-5' ? 'nav-active' : ''}>
                  <Link 
                    to="/signup/step-5"
                    className='nav-links'
                  >
                    5.Adım
                  </Link>
                </div>
              </nav>
              </React.Fragment>
              : <h2 className="webSectionTitle">Merhaba!</h2>
          } 
        </div>
      </header>      

      </React.Fragment>
    );
  }
}

export default withRouter(Header);