import React, { useRef } from 'react';
import handleViewport from 'react-in-viewport';
import { Link } from 'react-router-dom';
import Contact from '../contact/contact.component';
import './about.styles.scss';



const About = () => {
  return(
    <React.Fragment>
      <div id="bizkimizID" className="aboutSectionWrapper">
        <h2 className="webSectionTitle">BİZ KİMİZ</h2>
        <div className="aboutRow aboutFirstRow">

          <div className="aboutGridWrapper">
            <div className="aboutIntro">
              <p>
              Türkiye medya sektöründe, medyada yer almaya daha istekli ve sosyo-kültürel olarak bu konuda daha rahat davranan erkek uzmanlara öncelik verme konusunda uzun süredir devam eden bir mesleki alışkanlık var.
              </p>
              <p>
              Bize göre, medyada kadın uzmanların erkeklerden daha az görünmesinin iki ana nedeni var: 
              </p>              
              <p>
                Birincisi, program yapımcıları ve gazete editörleri misafir, uzman veya yorumcu ararken erkek ağırlıklı iletişim listelerine başvuruyorlar. Ve en iyi uzmanları aramak yerine, genellikle en kolay erişilebilen ve en müsait olanları tercih ediyorlar.
              </p>                            
              <p>
              İkincisi, pek çok kadın uzman televizyona çıkma, kamuya açık konuşma ve günlük gazetelere görüş verme ya da haberlerde yer alma konusunda isteksiz, deneyimsiz ya da güvensiz olabiliyor.
              </p>
              <p>
              Biz, Bana Kadınlar Anlatsın proje ekibi olarak, yazılı ve görsel medyada danışılan uzmanların oluşturduğu erkek egemen resmin değişmesine katkıda bulunmak istiyoruz. Toplumda yüksek seviyede yaygın olan kadına yönelik ayrımcı tutum ve şiddeti ortadan kaldırmanın bir yolunun, kadınların, topluma katkıları, fikirleri, bilgileri ve uzmanlıkları ile ön plana çıkmalarının olduğunu düşünüyoruz.              
              </p>         
              <p>
              Kadınların sosyal ve profesyonel hayata yaptıkları her türlü katkıyı tanımanın ve kimlik, sınıf, siyasi görüş, eğitim düzeyi gibi farklılıklardan bağımsız olarak bakış açılarını hak ettikleri seviyede görünür kılmanın daha fazla kadını kamusal alanda yer almaya teşvik edeceğine inanıyoruz.              
              </p>
            </div>
            <div className="ozgecmisWrapper">
              <h4 className="ozgecmisTitle">PROJE EKİBİ</h4>
              <Link to="/ozgecmisler" className="btnShowOzgecmis">ÖZGEÇMİŞLERİMİZ</Link>
            </div>              
          </div>     


        </div>
        <Contact />
      </div>
    </React.Fragment>
  );
}

export default About;