import React from 'react';
import './bottomlayer.styles.scss';

const BottomLayer = () => {
  const tmpYear = new Date().getFullYear();
  return(
    <React.Fragment>
      <div className="bottomLayerWrapper">
        <p>©{tmpYear} SensizOlmazSessizOlmaz.org. Her hakkı saklıdır.</p>
      </div>
    </React.Fragment>
  );
}

export default BottomLayer;