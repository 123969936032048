import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ProjectActionTypes } from '../../redux/project/project.types';
import { DefinitionsActionTypes } from '../../redux/definitions/definitions.types';
import './success.styles.scss';

class Success extends React.Component{

  constructor(props){
    super(props);
    this.state = {
       fullname:""
    };
    this.props.setStep1({
      ...this.props.step1,
      fullnameError: false
    });    
  }

  componentDidMount(){
    this.props.setCurrent('success');
    this.props.setStep1([]); 
    this.props.setStep2([]); 
    this.props.setStep3([]); 
    this.props.setStep4([]); 
    this.props.setStep5([]); 
  }

  render(){
    return(
      <section className="mainSection">
        <div className="sectionWrapper">
          <p className="girisYaziSuccess">
            İşleminiz başarıyla gerçekleşmiştir.
            <span>Birkaç dakika içinde e-mail adresinize gönderilecek aktivasyon mesajındaki linke tıklayarak profilinizi doğrulayabilirsiniz.</span> 
            <span>Gün içinde tarafınıza ayrıca bir onay mesajı gönderilecek, profiliniz sitede yayımlanmaya başlayacaktır.</span>
          </p>
          <img src='../../assets/images/success-result.png' alt="İşleminiz başarıyla gerçekleşmiştir. " />
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    step1: state.definitions.step1,
    step2: state.definitions.step2,
    step3: state.definitions.step3,
    step4: state.definitions.step4,
    step5: state.definitions.step5,
    warningMessage: state.project.warningMessageText,
    warningType: state.project.warningMessageType,
    currentPage: state.project.currentPage
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onWarning: (payload) => dispatch({ type: ProjectActionTypes.SET_WARNING_MESSAGE, payload: payload}),
    setDefault: () => dispatch({ type: ProjectActionTypes.SET_DEFAULT}),
    setStep1: (step1) => dispatch({ type: DefinitionsActionTypes.SET_STEP1, step1: step1}),
    setStep2: (step2) => dispatch({ type: DefinitionsActionTypes.SET_STEP2, step2: step2}),
    setStep3: (step3) => dispatch({ type: DefinitionsActionTypes.SET_STEP3, step3: step3}),
    setStep4: (step4) => dispatch({ type: DefinitionsActionTypes.SET_STEP4, step4: step4}),
    setStep5: (step5) => dispatch({ type: DefinitionsActionTypes.SET_STEP5, step5: step5}),
    setCurrent: (value) => dispatch({type: ProjectActionTypes.SET_CURRENT, value: value })
  }
}




export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Success));