import React, { useState, useEffect } from 'react';
import './educations.styles.scss';

const Educations = () => {

  const [eduLeft, setEduLeft] = useState(false);
  const [eduRight, setEduRight] = useState(false);

  const onMoreLeft = () => {
    setEduLeft(!eduLeft);
  }
  const onMoreRight = () => {
    setEduRight(!eduRight);
  }

  return(
    <React.Fragment>
      <div id="egitimlerIDWrapper">
        <div id="egitimlerID" className="educationsSectionWrapper">
          <h2 className="webSectionTitle">EĞİTİMLER</h2>
          <div className="educationImage"></div>        
          <div className="educationRow educationFirstRow">
            <img className="sessizOlmazLogo" src={process.env.PUBLIC_URL + '/assets/images/sensizolmaz-bottom.png'} alt="" />
            <div className="educationGridWrapper">
              <div className="educationIntro">
                <p className="">
                  Kadınları yok sayan medya ve diğer kurum ve kuruluşlar her zaman iki bahane öne sürer; bu konuda konuşacak kadın bulamadık ve konuşabileceğini düşündüğümüz kadınlar davetimizi reddettiler.
                </p>
                <p>Kadın uzmanlardan oluşan veri tabanının ilk bahaneyi ortadan kaldıracağını umuyoruz. İkinci bahanenin ise geçerli olduğuna inanmıyoruz. Ancak, kadınların medyada görünme konusunda isteksiz ve ne ile karşılaşacaklarından emin olamadıkları için daha titiz davrandıklarının da farkındayız.</p>
              </div>
            </div>
          </div>
          <div className="educationRow educationSecondRow">
            <div className="educationGridWrapper">
              <div className={`education educationleft ${eduLeft === true ? 'less' : ''}`}>
                <div className='triangle-up'></div>
                <h4 className="educationTitle">"Bana Kadınlar Anlatsın" çalışması kapsamında Türkiye’in birçok ilinde medya eğitimleri düzenlenecek.</h4>
                {
                // <span className="educationDate">5 Ocak 2021 14:00</span>
                // <span className="educationLocation">Beşiktaş, İstanbul</span>
                }
                <p className="educationContent">
                Bu eğitimlerde, medyaya haber kaynağı ya da uzman olarak görüş vermek ya da programlara konuk olmak hakkındaki süreçler ve beklentiler tanıtılacak. 
                </p>
                <p className={eduLeft === true ? 'educationContent' : 'educationContent hidden'}>Katılımcılara sosyal medyayı fark yaratacak biçimde kullanmaya ilişkin ipuçları sunulacak. Eğitim programları medya alanında aktif ve tecrübeli bir uzman takımı tarafından hazırlanacak ve verilecektir.</p>
                <button className="btnEduMore" onClick={onMoreLeft}>{`Daha ${eduLeft === true ? 'Az' : 'Fazla'} Göster`}</button>
              </div>
              <div className={`education educationright ${eduRight === true ? 'less' : ''}`}>
                <div className='triangle-up'></div>
                <h4 className="educationTitle">"Bana Kadınlar Anlatsın" projesi kapsamında ilk eğitimler tamamlandı. </h4>
                {
                // <span className="educationDate">25 Aralık 2020 19:00</span>
                // <span className="educationLocation">Online</span>
                }
                <p className="educationContent">Pandemi şartları sebebiyle çevrimiçi olarak gerçekleştirilen ilk eğitimler Türkiye’nin birçok farklı şehrinden uzman kadınlarla gerçekleştirildi.</p>
                <p className={eduRight === true ? 'educationContent' : 'educationContent hidden'}>İki günden oluşan eğitimlerde deneyimli gazeteciler medya ve toplumsal cinsiyet ilişkisinden, yazılı ve görsel medyaya görüş verirken dikkat edilmesi gereken noktalardan bahsediyor, sosyal medya uzmanı ise sosyal medya mecralarında etkili bir dil oluşturmanın püf noktalarını paylaşıyor.</p>
                <p className={eduRight === true ? 'educationContent' : 'educationContent hidden'}>Katılımcılara kamera önü pratiği kazandırabilmek amacıyla deneyimli haber spikerleri ile bir saatlik yoğun bir stüdyo deneyimi sağlanıyor. Ardından katılımcılara önceden verilmiş olan yazı örneği ödevlerine geri bildirim veriliyor.</p>
                <p className={eduRight === true ? 'educationContent' : 'educationContent hidden'}>Eğitim takviminden haberdar olabilmek ve eğitimler hakkında detaylı bilgi için sitemizi ve sosyal medya hesaplarımızı takip edebilir; proje ekibiyle iletişime geçebilirsiniz.</p>
                <button className="btnEduMore" onClick={onMoreRight}>{`Daha ${eduRight === true ? 'Az' : 'Fazla'} Göster`}</button>
              </div>
            </div>


          </div>

        </div>
      </div>
    </React.Fragment>
  );
}

export default Educations;