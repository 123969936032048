import React, { useState,useEffect } from 'react';
import { useLocation, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { ProjectActionTypes } from '../../redux/project/project.types';
import axios from 'axios';
import Pagination from "react-js-pagination";
import Select from "react-dropdown-select";
import Popup from 'reactjs-popup';
import { customShowLoadingLayer,customHideLoadingLayer,showWarningLayer, hideWarningLayer } from '../../utils/utils';
import {expertiesForSelect} from '../../utils/experties';

import NavBarSub from '../../components/navbarsub/navbarsub.component';
import 'reactjs-popup/dist/index.css';
import './searchresults.page.styles.scss';

const API_URL = process.env.REACT_APP_API_URL; 
const IMG_URL = process.env.REACT_APP_IMG_URL;

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

const SearchResultsPage = () => {
  let query = useQuery();
  const scrollRef = React.createRef()  
  const keywordx = query.get("keyword");
  let profession = query.get("profession")

  let page = (query.get("page") === null || query.get("page") === undefined || query.get("page") === "") ? 1 : query.get("page");
  const xprops = useSelector(state => state);
  const dispatch = useDispatch();
  const history = useHistory();

  const[searchResult, setSearchResult] = useState([]);
  const[activePage, setActivePage] = useState(page);
  const[searchCount, setSearchCount] = useState(null);
  const[keyword,setKeyword] = useState(keywordx);
  const[expert,setExpert] = useState([{label:(profession === null || profession === "") ? "Lütfen seçiniz" : profession,value:(profession === null || profession === "") ? "" : profession}]);
  const[formOpen,setFormOpen] = useState(false);
  
  const onWarning = (payload) => {
    dispatch({ type: ProjectActionTypes.SET_WARNING_MESSAGE, payload: payload});
  }
  const setSearchQueryParams = (payload) => {
    dispatch({ type: ProjectActionTypes.SET_SEARCH_QUERY_PARAMS, payload: payload});
  }

  useEffect(()=>{
    if (keywordx !== null || profession !== null){
      getSearchResults();
    }
   
  },[]);

  const getSearchResults = async () => {
    
    const fmData = new FormData();
    const config = {
      headers: { "content-type": "multipart/form-data" },
      crossDomain: true
    }

    //profession = profession === null ? "" : profession;

    fmData.append("keyword", keywordx);    
    fmData.append("profession", profession);    
    fmData.append("page", activePage);    
    let warnmessage = "";
    const res = await axios.post(
      API_URL+"getsearchresults",
      fmData,
      config
    );
    
    if (res.data.type === "done"){
      let tmpSearchCount = parseInt(res.data.data.total);
      
      if (isNaN(tmpSearchCount) || tmpSearchCount < 1){
        setSearchCount(0);
      }else{
        setSearchCount(parseInt(tmpSearchCount));      
        setSearchResult(res.data.data.data);
      }
      customHideLoadingLayer();   
    }else{
      if (res.data.message === "DP_Params"){
        warnmessage = "Yanlış arama kriteri. Lütfen tekrar deneyiniz.";
      }else{
        warnmessage = "Bir hata oluştu. Lütfen tekrar deneyiniz.";
      }
      setSearchCount(0);
      onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
      showWarningLayer(); 
      customHideLoadingLayer();   
    }    
  }


  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    filterSearch(pageNumber);
  }

  const handleScrollToElement = () => {
    scrollRef.current.scrollTo(0,0);
  }

  const handleCloseModal = () => {
    setFormOpen(false);
  }

  const handleChangeKeyword = (event) => {
    setKeyword(event.target.value);
  }

  const handleChangeExpert = (e) => {
    setExpert(e);
  }  

  const filterSearch = (pageNumber) => {

    customShowLoadingLayer();
    let warnmessage = "";
    if ((expert === null || expert[0].value === "") && (keyword === null || keyword === "")){
      warnmessage = "Arama kritirleri beraber boş olamaz."
      onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
      showWarningLayer(); 
      customHideLoadingLayer();
      return;
    }

    //const profession = expert === null ? "" : expert;
    let url = "/arama-sonuclari?page="+pageNumber;

    if (keyword !== null && keyword !== ""){
      url = url + "&keyword="+keyword;
      if (expert !== null && expert[0].value !== ""){
        url = url + "&profession="+expert[0].value;
      }
    }else{
      if (expert !== null && expert[0].value !== ""){
        url = url + "&profession="+expert[0].value;
      }      
    }

    setSearchQueryParams(
      {
        page:pageNumber,
        keyword: keyword !== null ? keyword : "",
        profession: expert !== null && expert[0].value !== "" ? expert[0].value : ""
      }
    );      

    
    history.push(url);
    window.location.reload();
    
  }  

  const goToDetails = (eid,ename) => {
    let url = "/uzman/"+eid+"/"+ename;
    customShowLoadingLayer();
    history.push(url);
  }
  
  return(
    <React.Fragment>
      <div className="searchSectionWrapper">
        <NavBarSub />
        <div className="searchWrapper">
          <div className="searchList">
            <div className="searchListSticky">
              <h2 className="webSectionTitle">Arama Kriterleri</h2>
              <ul className="ulsearchList">
                <li className="searchFilterTitle">
                  Anahtar Kelime
                </li>
                <li className="searchFilterContent">
                <div className="inputWrapper web">
                  <input type="text" placeholder="Anahtar kelime" className="editsfilter" value={keyword} onChange={(event)=>handleChangeKeyword(event)}  />
                </div>
                </li>   
                <li className="searchFilterTitle">
                  Uzmanlık Alanı
                </li>
                <li className="searchFilterContent">
                  <div className="inputWrapper web">
                  <Select
                    multi={false}
                    placeholder="Lütfen seçiniz."
                    options={expertiesForSelect}
                    values={expert} // set selected value
                    color="#9b897c"
                    searchable={false}
                    className="editsfilter"
                    onChange={handleChangeExpert}
                  />  
                  
              
                </div>                   
                </li>  
                <li className="searchFilterContent">
                  <div className="inputWrapper web buttonwrapper">
                    <button className="btnWeb btnFilterSearch" onClick={()=>filterSearch(1)}>UZMAN BUL</button>
                  </div> 
                </li>                              
              </ul>
            </div>
          </div>
          <div className="searchContent">
            <h2 className="webSectionTitle">Arama Sonuçları</h2>
              {
                searchCount > 0 ?
                <React.Fragment>
                 <p className="searchResultInfoText">Anahtar kelime <strong>{keywordx === null ? "Belirtilmemiş" : keywordx}</strong> ve uzmanlık alanı <strong>{(profession === null || profession === "") ? "Belirtilmemiş" : profession}</strong> için <span className="expertCount">"{searchCount}"</span> uzman bulundu.</p>
                 <div className="lastExpertsContentWrapper">
                  {
                    searchResult.length > 0 &&
                      searchResult.map(({DOCUMENTID,USERID,DOCUMENTNAME,...otherProps},index) => (
                        <div className="lastExpertCard">
                          <img className="lastExpertImg" src={`https://www.sensizolmazsessizolmaz.org/uploads/normal_profile_${USERID}.jpg`} alt={DOCUMENTNAME} onError={(e)=>{e.target.onerror = null; e.target.src="/assets/images/default-woman.jpg"}} />
                          <h4 className="lastExpertName">{DOCUMENTNAME}</h4>
                          <p className="lastExpertTitle">{otherProps.YASADIGIULKE} / {otherProps.YASADIGISEHIR} </p>
                          <div className="expertButtonWrapper">
                            <button className="btnWeb lastExpertDetailBtn" onClick={()=>goToDetails(DOCUMENTID,DOCUMENTNAME)}>Detay</button>
                          </div>
                        </div>
                      ))
                  }
               </div>                 
                <div className="paginationWrapper">
                 <Pagination
                 activePage={activePage}
                 itemsCountPerPage={25}
                 totalItemsCount={searchCount}
                 pageRangeDisplayed={5}
                 onChange={handlePageChange}
                 disabledClass="disabled"
               />
               </div>
                </React.Fragment> : searchCount === 0 ? <p>Aradığınız kriterlerde uzman bulunamadı. Lütfen tekrar deneyiniz.</p> : <p>Yükleniyor...</p>  
              }
          </div>
        </div>

      </div>
      <Popup open={formOpen} closeOnDocumentClick onClose={handleCloseModal} onOpen={handleScrollToElement} position="top center">      
        <div ref={scrollRef} className="modal modalForm">
              test 1 2 3
        </div>
      </Popup>
    </React.Fragment>
  );
};

export default SearchResultsPage;