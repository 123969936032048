import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import axios from 'axios';
import { customShowLoadingLayer,customHideLoadingLayer,showWarningLayer, hideWarningLayer } from '../../utils/utils';
import NavBarSub from '../../components/navbarsub/navbarsub.component';

import './resetpassword.styles.scss';
const API_URL = process.env.REACT_APP_API_URL; 

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

const ResetPasswordPage = () => {
  let query = useQuery();
  const email = query.get("e");
  const rid = query.get("rid");
  //console.log(email);
  //console.log(rid);
    
  const [state, setState] = useState({
    message:"",
    status:false,
  });

	const resetPasswordByLink = async (r,e) => {
		let result = false;
		if (state.status === false){
			//const res = await axios.post(`https://komut.sensizolmazsessizolmaz.com/sifre-link-sifirla`,{ "rid": r, "email": e},{crossDomain: true});
      const fmData = new FormData();
      const config = {
        headers: { "content-type": "multipart/form-data" },
        crossDomain: true
      }
  
      fmData.append("r", r);    
      fmData.append("e", e);    
      
      let warnmessage = "";
      const res = await axios.post(
        API_URL+"resetpasswordbylink",
        fmData,
        config
      );
  
			if (res.data.type === "error"){
				result = false;
        setState({...state,status:false,message:"Geçersiz link veya e-posta adresi."});
			} else{
				if (res.data.message === "DP_Done"){
					setState({...state,status:true,message:"Şifreniz başarıyla sıfırlandı ve e-posta adresinize gönderilmiştir.Lütfen e-posta adresinizi kontrol edin."});
					result = true;
				}else{
					setState({...state,status:false,message:"Geçersiz link veya e-posta adresi."});
					result = false;
				}
			} 
      customHideLoadingLayer();
			return result;
		}else{
      customHideLoadingLayer();
			return false;
		}
		
	};  

  useEffect(() => {
		if (email === "" || rid === ""){
			setState(
        {message:"Geçersiz link veya e-posta adresi."}
      );
		}else{
      customShowLoadingLayer();
			resetPasswordByLink(rid,email);
		}
  },[])

  return(
    <React.Fragment>
      <div className="cvsSectionWrapper">
        <NavBarSub />
        <div className="cvsWrapper">
          <div className="cvList">
            <div className="cvListSticky">
              <h2 className="webSectionTitle">Şifre Resetle</h2>
            </div>
          </div>
          <div className="cvContent">
            <p className="resetMessage">{state.message}</p>
            <Link className="btnWeb btnFilterSearch resetHome" to="/">Ana Sayfaya Git</Link>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ResetPasswordPage;