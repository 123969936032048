import React, { useState, useEffect } from 'react';
import Popup from 'reactjs-popup';
import { Link, useHistory } from 'react-router-dom';
import { project } from '../../utils/project';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import axios from 'axios';
import { customShowLoadingLayer,customHideLoadingLayer,showWarningLayer, hideWarningLayer,validateEmail, useFocus } from '../../utils/utils';
import { ProjectActionTypes } from '../../redux/project/project.types';
import { LoginActionTypes } from '../../redux/login/login.types';
import * as actionCreators from '../../redux/login/login.actions';
import * as actionDefinitionCreators from '../../redux/definitions/definitions.actions';
import 'reactjs-popup/dist/index.css';
import './loginpopup.styles.scss';



const LoginPopup = ({formOpenStatus, loginFormType}) => {
  const xprops = useSelector(state => state);
  const dispatch = useDispatch();
  let history = useHistory();
  
  const [emailRef, setEmailFocus] = useFocus();
  const [passwordRef, setPasswordFocus] = useFocus();
  //const [loginRef, setLoginFocus] = useFocus();
  const [formType,setFormType] = useState(loginFormType);
  const [formOpen,setFormOpen] = useState(formOpenStatus);
  const [showForgetPassword, setForgetPassword] = useState(false);
  const [username,setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [lostemail,setLostemail] = useState("")


  const handleChangeUsername = (e) => setUsername(e.target.value);
  const handleChangePassword = (e) => setPassword(e.target.value);
  const handleChangeLostEmail = (e) => setLostemail(e.target.value);
  
  const handleToggleForgetPassword = () => setForgetPassword(!showForgetPassword);

  const onWarning = (payload) => {
    dispatch({ type: ProjectActionTypes.SET_WARNING_MESSAGE, payload: payload});
  }

  const onSuccess = (loginData) => {
    dispatch({ type: LoginActionTypes.LOGIN_SUCCESS, loginData: loginData});
  }

  

  const openModal = () => dispatch(actionCreators.setFormLoginStatus(true));
  const closeModal = () => dispatch(actionCreators.setFormLoginStatus(false));

  const logOut = () => dispatch(actionCreators.userLogout());
  const handleLogout = (event,isOpenLogin,isOpenMessage) => {
    customShowLoadingLayer();  
    onWarning({warningMessageType:'success', warningMessageText: 'Çıkış yapıldı.'});
    showWarningLayer();
    logOut();
    setTimeout(() => {
      if (isOpenMessage === true){
        dispatch(actionCreators.setChatMessageAfterLogin(true));
      }
      if (isOpenLogin){
        openModal();
      }
      customHideLoadingLayer();  
      hideWarningLayer();
    },3000);
  } 

  const resetPassword = async () => {
    customShowLoadingLayer(); 
    const fmData = new FormData();
    if (lostemail !== null && validateEmail(lostemail)){
      fmData.append("email", lostemail);
      const config = {
        headers: { "content-type": "multipart/form-data" },
        crossDomain: true
      }
  
      const res = await axios.post(
        "https://www.sensizolmazsessizolmaz.org/html/do_forgetpassword.php",
        //"http://localhost/soso/new-participant-form",
        fmData,
        config
      );
      if (res.data.type === "error"){
        if (res.data.message === "DP_Session"){
          logOut();
          customHideLoadingLayer(); 
        }else if (res.data.message === "DP_Email"){
          onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen e-posta adresini kontrol edin.'});
          showWarningLayer();
          customHideLoadingLayer(); 
        }else{
          onWarning({warningMessageType:'fail', warningMessageText: 'Bir hata oluştu. Lütfen tekrar deneyiniz.'});
          showWarningLayer();
          customHideLoadingLayer();
        }
      } else{
        if (res.data.message === "DP_Done"){
          onWarning({warningMessageType:'done', warningMessageText: 'Şifre resetleme linki e-posta adresinize gönderildi.'});
          showWarningLayer();
          customHideLoadingLayer();
        }else{
          onWarning({warningMessageType:'fail', warningMessageText: 'Bir hata oluştu. Lütfen tekrar deneyiniz.'});
          showWarningLayer();
          customHideLoadingLayer();
        }
      }   
      


    }else{
      onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen e-posta adresini kontrol edin.'});
      customHideLoadingLayer();
      showWarningLayer();
      return false;
    }
  }  

  const login = async (goToMessage) => {
    customShowLoadingLayer();  
    let warnmessage = "";
   
    if (username === "" || username === undefined || username === null ){
      warnmessage = "Lütfen e-posta adresinizi kontrol edin.";
      onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
      showWarningLayer();
      setEmailFocus();
      customHideLoadingLayer();      
      return;    
    }else{
      if (!validateEmail(username)){
        warnmessage = "Lütfen e-posta adresinizi kontrol edin.";
        onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
        showWarningLayer();
        setEmailFocus();
        customHideLoadingLayer();      
        return;        
      }
    }    

    if (password === "" || password === undefined || password === null ){
      warnmessage = "Lütfen şifre alanını kontrol edin.";
      onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
      showWarningLayer();
      setPasswordFocus();
      customHideLoadingLayer();      
      return;    
    }   

    const fmData = new FormData();
    fmData.append("username", username);
    fmData.append("password", password);
    fmData.append("utype",loginFormType);
    const config = {
      headers: { "content-type": "multipart/form-data" },
      crossDomain: true
    }
    const loginResult = await axios.post(`https://www.sensizolmazsessizolmaz.org/html/do_actionlogin.php`,
          fmData,
          config);

    if (loginResult.data.type === "success"){
      customShowLoadingLayer();
      onSuccess(loginResult.data.data);
      warnmessage = "Giriş yapıldı.";
      closeModal();
      if (xprops.login.chatMessageAfterLogin === true){
        dispatch(actionDefinitionCreators.setFormChatStatus(true));
        dispatch(actionCreators.setChatMessageAfterLogin(false));
      }
      onWarning({warningMessageType:'success', warningMessageText: warnmessage});      
      showWarningLayer();
      if (loginFormType !== "medya"){
        history.push('/');
        history.replace("/home");
      }
    }else{
      onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen kullanıcı adı veya şifrenizi kontrol edin.'});
      showWarningLayer();
      customHideLoadingLayer();
    }    
    customHideLoadingLayer();
  }
  useEffect(()=>{
    window.addEventListener("beforeunload", closeModal);
    return () =>{
      window.removeEventListener("beforeunload",closeModal);
    }    
  },[]);

  return(
      <Popup className="loginFormWrapper" open={xprops.login.formLoginOpenStatus} onClose={closeModal} position="top center" closeOnDocumentClick={false}>
        <button className="fixclose" onClick={closeModal}><img src="/assets/images/close-button.png" alt="" className="closeButton" /></button>
        {
          formType === "medya" ? 
          <React.Fragment>
            {
              xprops.login.isLoggedIn && xprops.login.User.GroupID !== process.env.REACT_APP_MEDYA_GROUP ?
              <div className="modal modalForm">
                <div className="modalWrapper">
                  <h2 className="loginTitle">Medya - Giriş</h2>
                  <hr/>
                  <div className="loginSection">
                    <h4 className="text-center">Lütfen medya hesabı ile giriş yapmak için önce çıkış yapınız.</h4>
                    <div className="loginSectionFooter center">
                      <div>
                        <button className="btnWeb lastExpertDetailBtn btnLogin" onClick={(event)=> handleLogout(event,true,true)}>Çıkış</button>
                      </div>
                    </div>
                  </div>                
                </div>
              </div>
               :
               <div className="modal modalForm">
               <div className="modalWrapper">
                 {
                   showForgetPassword === false ?
                   <React.Fragment>
                     <h2 className="loginTitle">Medya - Giriş</h2>
                     <hr/>
                     <div className="loginSection">
                       <h4>E-Posta Adresi</h4>
                       <input ref={emailRef} type="text" placeholder="Lütfen e-posta adresinizi giriniz." className="edits" value={username} onChange={handleChangeUsername} onKeyPress={(e)=> e.key === 'Enter' && setPasswordFocus()} />
                       <h4>Şifre</h4>
                       <input ref={passwordRef}  type="password" placeholder="Şifrenizi giriniz." className="edits" value={password} onChange={handleChangePassword} onKeyPress={(e)=> e.key === 'Enter' && login()}  />
                       <button className="btnForgetPassword" onClick={handleToggleForgetPassword}>Şifremi Unuttum</button>
                       <div className="loginSectionFooter">
                         <div>
                           Hesabınız yok mu ? <Link to='/medyakayit' className="btnForgetPassword btnForgetPasswordSignUp">Kayıt Ol</Link>
                         </div>
                         <div>
                           <button className="btnWeb lastExpertDetailBtn btnLogin" onClick={login}>Giriş</button>
                         </div>
 
                       </div>
 
                     </div>
                   </React.Fragment>
                   :
                   <React.Fragment>
                     <h2 className="loginTitle">Şifremi Unuttum</h2>
                     <hr/>
                     <div className="forgetSection">
                         <h4>E-Posta Adresi</h4>
                         <input type="text" placeholder="Lütfen e-posta adresinizi giriniz." className="edits" value={lostemail} onChange={handleChangeLostEmail}/>
                         <div className="loginSectionFooter">
                           <div>
                             Hesabınız yok mu ? <Link to='/medyakayit' className="btnForgetPassword btnForgetPasswordSignUp">Kayıt Ol</Link> <span className="loginLinkSpacer">yada</span> <button className="btnForgetPassword" onClick={handleToggleForgetPassword}>Giriş Yap</button>
                           </div>
                           <div>
                             <button className="btnWeb lastExpertDetailBtn btnLogin" onClick={resetPassword}>Gönder</button>
                           </div>
 
                         </div>              
                     </div>
                   </React.Fragment>
                 }
               </div>
             </div>
 

            }
          </React.Fragment>
          :
          <React.Fragment>
            <div className="modal modalForm">
              <div className="modalWrapper">
                {
                  showForgetPassword === false ?
                  <React.Fragment>
                    <h2 className="loginTitle">Giriş</h2>
                    <hr/>
                    <div className="loginSection">
                      <h4>E-Posta Adresi</h4>
                      <input ref={emailRef} type="text" placeholder="Lütfen e-posta adresinizi giriniz." className="edits" value={username} onChange={handleChangeUsername} onKeyPress={(e)=> e.key === 'Enter' && setPasswordFocus()} />
                      <h4>Şifre</h4>
                      <input ref={passwordRef}  type="password" placeholder="Şifrenizi giriniz." className="edits" value={password} onChange={handleChangePassword} onKeyPress={(e)=> e.key === 'Enter' && login()}  />
                      <button className="btnForgetPassword" onClick={handleToggleForgetPassword}>Şifremi Unuttum</button>
                      <div className="loginSectionFooter">
                        <div>
                          Hesabınız yok mu ? <Link to='/signup' className="btnForgetPassword btnForgetPasswordSignUp">Kayıt Ol</Link>
                        </div>
                        <div>
                          <button className="btnWeb lastExpertDetailBtn btnLogin" onClick={login}>Giriş</button>
                        </div>

                      </div>

                    </div>
                  </React.Fragment>
                  :
                  <React.Fragment>
                    <h2 className="loginTitle">Şifremi Unuttum</h2>
                    <hr/>
                    <div className="forgetSection">
                        <h4>E-Posta Adresi</h4>
                        <input type="text" placeholder="Lütfen e-posta adresinizi giriniz." className="edits" value={lostemail} onChange={handleChangeLostEmail}/>
                        <div className="loginSectionFooter">
                          <div>
                            Hesabınız yok mu ? <Link to='/signup' className="btnForgetPassword btnForgetPasswordSignUp">Kayıt Ol</Link> <span className="loginLinkSpacer">yada</span> <button className="btnForgetPassword" onClick={handleToggleForgetPassword}>Giriş Yap</button>
                          </div>
                          <div>
                            <button className="btnWeb lastExpertDetailBtn btnLogin" onClick={resetPassword}>Gönder</button>
                          </div>

                        </div>              
                    </div>
                  </React.Fragment>
                }
              </div>
            </div>
          </React.Fragment>
        }
      </Popup>         
  );

};

export default LoginPopup;