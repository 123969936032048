import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import ContactListItem from '../contactlist-item/contactlist-item.component';
import axios from 'axios';
import { customShowLoadingLayer,customHideLoadingLayer,showWarningLayer, hideWarningLayer,validateEmail, useFocus } from '../../utils/utils';

import './contactlist.styles.scss';

const ContactList = ({contactList,getExpertMessages,getContactList,selectedId}) => {
  const xprops = useSelector(state => state);
  const dispatch = useDispatch();

 return(
  <div className="contactListWrapper">
    {
      contactList !== null && contactList !== undefined &&
        contactList.map((item,index)=>{
          return(
            <ContactListItem 
              key={index}
              properties={item}
              isSelected={item.selected}
              getExpertMessages={getExpertMessages}
              contactListData={contactList}
              selectedId={selectedId}
              getContactList={getContactList}
              gid={xprops.login.User.GroupID}  
            />
          )
        })
    }

  </div>
 );
}

export default ContactList;