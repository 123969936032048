import React, { useState, useEffect } from 'react';
import Popup from 'reactjs-popup';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import axios from 'axios';
import { customShowLoadingLayer,customHideLoadingLayer,showWarningLayer, useFocus, formatNowDateTime } from '../../utils/utils';
import MessageList from '../messagelist/messagelist.component';
import ContactList from '../contactlist/contactlist.component';
import { ProjectActionTypes } from '../../redux/project/project.types';
import { DefinitionsActionTypes } from '../../redux/definitions/definitions.types';
import * as actionCreators from '../../redux/definitions/definitions.actions';
import 'reactjs-popup/dist/index.css';
import './chatpopup.styles.scss';



const ChatPopup = ({formOpen}) => {
  const xprops = useSelector(state => state);
  const dispatch = useDispatch();
  const [messageRef, setMessageFocus] = useFocus();
  const [chatText,setChatText] = useState("")
  const [charLimit,setCharLimit] = useState(0)
  const [messageListData,setMesageListData] = useState(null);
  const [contactListData,setContactListData] = useState([]);
  const [selectedUser,setSelectedUser] = useState(null);
  const [selectedId,setSelectedId] = useState(null);


  const handleChangeText = (e) => {
    if (e.target.value.length <= 250){
      setChatText(e.target.value);
    }else{
      onWarning({warningMessageType:'warning', warningMessageText: "Tek seferde gönderilebilen karakter limitine ulaştınız."});      
      showWarningLayer();
    }
    setCharLimit(e.target.value.length);

  } 

  const onWarning = (payload) => {
    dispatch({ type: ProjectActionTypes.SET_WARNING_MESSAGE, payload: payload});
  }
  const closeModal = () => {dispatch(actionCreators.setFormChatStatus(false));setContactListData([]);}


  const refreshMessage = () => {
    if(xprops.login.User.ViewType === "medya"){
      if (xprops.project.expertDetails !== null){
        let tmpObj = {
          "fid":xprops.login.User.UserID,
          "tid":xprops.project.expertDetails.USERID,
          "img":xprops.project.expertDetails.PICTUREEXT !== undefined && xprops.project.expertDetails.PICTUREEXT !== null && xprops.project.expertDetails.PICTUREEXT !== "" ? "https://www.sensizolmazsessizolmaz.org/uploads/thumb_profile_"+xprops.project.expertDetails.USERID+".jpg" : "https://www.sensizolmazsessizolmaz.org/assets/images/default-woman.jpg",
          "fullname":xprops.project.expertDetails.FULLNAME,
          "sender":xprops.login.User.Extra[0].DOCUMENTNAME,
          "receiver":xprops.project.expertDetails.FULLNAME,
          "createdate": formatNowDateTime(),
          "selected":true
        }
        //getExpertMessages(tmpObj.tid,tmpObj.fid,true,tmpObj,null);
        getContactList(xprops.login.User.UserID,xprops.project.expertDetails.USERID,tmpObj);
      }
    }else{
      getContactList(xprops.login.User.UserID,xprops.login.User.UserID);
    }

  }

  useEffect(()=>{
    //Run open and close
    console.log(xprops.login.User);
    console.log(xprops.project.expertDetails);
    if (xprops.definitions.formChatOpenStatus === true){ //Run code on chat window is open
      setCharLimit(0);
      setChatText("");
      setTimeout(()=>{
        setMessageFocus();
      },500);
      
      if (xprops.login.isLoggedIn === true && xprops.login.User.GroupID === process.env.REACT_APP_MEDYA_GROUP){
        if (xprops.project.expertDetails !== null){
          let tmpObj = {
            "fid":xprops.login.User.UserID,
            "tid":xprops.project.expertDetails.USERID,
            "img":xprops.project.expertDetails.PICTUREEXT !== undefined && xprops.project.expertDetails.PICTUREEXT !== null && xprops.project.expertDetails.PICTUREEXT !== "" ? "https://www.sensizolmazsessizolmaz.org/uploads/thumb_profile_"+xprops.project.expertDetails.USERID+".jpg" : "https://www.sensizolmazsessizolmaz.org/assets/images/default-woman.jpg",
            "fullname":xprops.project.expertDetails.FULLNAME,
            "sender":xprops.login.User.Extra[0].DOCUMENTNAME,
            "receiver":xprops.project.expertDetails.FULLNAME,
            "createdate": formatNowDateTime(),
            "selected":true
          }
          //getExpertMessages(tmpObj.tid,tmpObj.fid,true,tmpObj,null);
          getContactList(xprops.login.User.UserID,xprops.project.expertDetails.USERID,tmpObj);
        }
  
      }else if(xprops.login.isLoggedIn === true && xprops.login.User.GroupID === process.env.REACT_APP_KADIN_UZMAN){
        getContactList(xprops.login.User.UserID,xprops.login.User.UserID);
      }      


      
    }

    

  },[xprops.definitions.formChatOpenStatus]);


  useEffect(()=>{
    window.addEventListener("beforeunload", closeModal);
    return () =>{
      window.removeEventListener("beforeunload",closeModal);
    }    
  },[]);

  const getExpertMessagesd = async (tid,fid,isContact,contactlist,selectedId) => {
    customShowLoadingLayer();   
    let warnmessage = "";
    if (tid === null || tid === ""){
      warnmessage = "Bir hata oluştu. Lütfen tekrar deneyiniz.";
      onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
      showWarningLayer(); 
      customHideLoadingLayer();   
    }
    const fmData = new FormData();
    const config = {
      headers: { "content-type": "multipart/form-data" },
      crossDomain: true
    }

    fmData.append("tid", tid);
    fmData.append("fid", fid);        
    fmData.append("u", xprops.login.User.UserName);
    fmData.append("p", xprops.login.User.Passwordx);
    fmData.append("gi", xprops.login.User.GroupID);
    
    
    const res = await axios.post(
      process.env.REACT_APP_API_URL+"getexpertmessages",
      fmData,
      config
    );
    
    if (res.data.type === "done"){
      if (isContact === true){
        if (selectedId === null){
          setSelectedUser(contactlist);
          if (res.data.contactList.length > 0){
            if (res.data.contactList.find(c => c.tid === contactlist.tid) === undefined){
              res.data.contactList.unshift(contactlist);      
            }else{
              let objIndex = res.data.contactList.findIndex(c => c.tid === contactlist.tid);
              let tmpList = [...res.data.contactList];
              tmpList[objIndex].selected = true;
              res.data.contactList = tmpList;
            }
          }else{
            res.data.contactList.unshift(contactlist);      
          }
        }else{
          if (res.data.contactList.length > 0){
            let objIndex = res.data.contactList.findIndex(c => c.tid === selectedId);
            let tmpList = [...res.data.contactList];
            tmpList[objIndex].selected = true;
            res.data.contactList = tmpList;

            let tmpObj = {
              "fid":tmpList[objIndex].tid,
              "tid":tmpList[objIndex].fid,
              "img":tmpList[objIndex].img,
              "fullname":tmpList[objIndex].fullname,
              "sender":tmpList[objIndex].sender,
              "receiver":tmpList[objIndex].receiver,
              "createdate":tmpList[objIndex].createdate,
              "selected":true
            }   
            setSelectedUser(tmpObj);         
            console.log(tmpObj);


          }
        }

        setContactListData(res.data.contactList);
      }else{
        if (selectedId !== null){
          if (res.data.contactList.length > 0){
            let objIndex = res.data.contactList.findIndex(c => c.tid === selectedId);
            let tmpList = [...res.data.contactList];
            console.log(tmpList);
            tmpList[objIndex].selected = true;
            res.data.contactList = tmpList;
            let tmpObj = {
              "fid":tmpList[objIndex].tid,
              "tid":tmpList[objIndex].fid,
              "img":tmpList[objIndex].img,
              "fullname":tmpList[objIndex].fullname,
              "sender":tmpList[objIndex].sender,
              "receiver":tmpList[objIndex].receiver,
              "createdate":tmpList[objIndex].createdate,
              "selected":true
            }   
            setSelectedUser(tmpObj);                
          }          
        }
      }
      
      setSelectedId(tid);
      setMesageListData(res.data.data);
      customHideLoadingLayer();   
    }else{
      warnmessage = "Bir hata oluştu. Lütfen tekrar deneyiniz.";      
      onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
      showWarningLayer(); 
      customHideLoadingLayer();   
    }    
  }

  const getContactList = async (fid,tid,expertObj=null) => {
    console.log(expertObj);
    customShowLoadingLayer();   
    let warnmessage = "";
    if (fid === null || fid === ""){
      warnmessage = "Bir hata oluştu. Lütfen tekrar deneyiniz.";
      onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
      showWarningLayer(); 
      customHideLoadingLayer();   
    }
    if (tid === null || tid === ""){
      warnmessage = "Bir hata oluştu. Lütfen tekrar deneyiniz.";
      onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
      showWarningLayer(); 
      customHideLoadingLayer();   
    }    
    const fmData = new FormData();
    const config = {
      headers: { "content-type": "multipart/form-data" },
      crossDomain: true
    }

    fmData.append("fid", fid);
    fmData.append("tid", tid);
    fmData.append("u", xprops.login.User.UserName);
    fmData.append("p", xprops.login.User.Passwordx);
    fmData.append("gi", xprops.login.User.GroupID);
    
    
    const res = await axios.post(
      process.env.REACT_APP_API_URL+"getcontactlist",
      fmData,
      config
    );
    
    if (res.data.type === "done"){
      if (xprops.login.User.ViewType === "medya"){
        if (res.data.contactList !== null && res.data.contactList.length > 0){
          if (expertObj !== null){
            if (res.data.contactList.find(c => c.tid === expertObj.tid) === undefined){
              res.data.contactList.unshift(expertObj);      
            }
            setMesageListData(res.data.data);  
          }
        }else{
          res.data.contactList.unshift(expertObj);      
          setMesageListData([]);
        }
        setContactListData(res.data.contactList);
        setSelectedId(expertObj.tid);
        setSelectedUser(expertObj);
        customHideLoadingLayer();   
        console.log(expertObj);
      }else{
        setContactListData(res.data.contactList);
        setSelectedId(res.data.data[0].TOID);
        let tmpObj = {
          "fid":res.data.contactList[0].fid,
          "tid":res.data.contactList[0].tid,
          "img":res.data.contactList[0].img,
          "fullname":res.data.contactList[0].fullname,
          "sender":res.data.contactList[0].sender,
          "receiver":res.data.contactList[0].receiver,
          "createdate":res.data.contactList[0].createdate,
          "selected":true
        }   
        setSelectedUser(tmpObj);        
        setMesageListData(res.data.data);
        customHideLoadingLayer();   
        setTimeout(()=>{
          setMessageFocus();
        },1000)
        console.log(tmpObj);
      }
    }else{
      warnmessage = "Bir hata oluştu. Lütfen tekrar deneyiniz.";      
      onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
      showWarningLayer(); 
      customHideLoadingLayer();   
    }    
  }

  const sendMessage = async () => {
    //setMesageListData(null);
    customShowLoadingLayer();   
    let warnmessage = "";
    //console.log(selectedUser);
    //return;
    if (selectedUser === null || selectedUser === undefined || selectedUser.tid === null){
      warnmessage = "Bir hata oluştu. Lütfen tekrar deneyiniz.";
      onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
      showWarningLayer(); 
      customHideLoadingLayer();   
      //setMesageListData([]);
      return false;
    }

    if (chatText === null || chatText === undefined || chatText === ""){
      warnmessage = "Lütfen mesaj alanını kontrol ediniz.";
      onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
      showWarningLayer(); 
      customHideLoadingLayer();   
      //setMesageListData([]);
      return false;
    }    


    const fmData = new FormData();
    const config = {
      headers: { "content-type": "multipart/form-data" },
      crossDomain: true
    }
    let tidx = "";
    let fidx = "";
    if (selectedUser.tid === xprops.login.User.UserID){
      tidx = selectedUser.fid;
      fidx = selectedUser.tid;
      fmData.append("tid", selectedUser.fid);
      fmData.append("fid", selectedUser.tid);        
      fmData.append("tname", selectedUser.sender);
      fmData.append("fname", selectedUser.receiver);
    }else{
      if(xprops.login.User.ViewType === "medya"){
        tidx = selectedUser.tid;
        fidx = selectedUser.fid;
        fmData.append("tid", selectedUser.tid);
        fmData.append("fid", selectedUser.fid);        
        fmData.append("tname", selectedUser.receiver);
        fmData.append("fname", selectedUser.sender);
      }else{
        tidx = selectedUser.tid;
        fidx = xprops.login.User.UserID;
        fmData.append("tid", selectedUser.tid);
        fmData.append("fid", xprops.login.User.UserID);        
        fmData.append("tname", selectedUser.sender);
        fmData.append("fname", selectedUser.receiver);
      }

    }

    fmData.append("u", xprops.login.User.UserName);
    fmData.append("p", xprops.login.User.Passwordx);
    fmData.append("gi", xprops.login.User.GroupID);
    fmData.append("message",chatText);
        
    const res = await axios.post(
      process.env.REACT_APP_API_URL+"sendmessage",
      fmData,
      config
    );
    
    if (res.data.type === "done"){
      warnmessage = "Mesajınız gönderildi.";      
      onWarning({warningMessageType:'done', warningMessageText: warnmessage});      
      showWarningLayer(); 
      customHideLoadingLayer();   
      setChatText("");
      setCharLimit(0);
      //getExpertMessages(selectedUser.tid,selectedUser.fid,false,null,null);
      if(xprops.login.User.ViewType === "medya"){
        getContactList(fidx,tidx,selectedUser);
      }else{
        getContactList(xprops.login.User.UserID,xprops.login.User.UserID);
      }
      
    }else{
      warnmessage = "Bir hata oluştu. Lütfen tekrar deneyiniz.";      
      onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
      showWarningLayer(); 
      customHideLoadingLayer();   
      //setMesageListData([]);
    } 
  }

  return(
      <Popup className="chatFormWrapper" open={xprops.definitions.formChatOpenStatus} onClose={closeModal} position="top center" closeOnDocumentClick={false}>
        <button className="fixclose" onClick={closeModal}><img src="/assets/images/close-button.png" alt="" className="closeButton" /></button>
          <div className="modal modalForm">
            <div className="modalWrapper">
              <h2 className="chatWindowTitle">Mesajlar <img className="messageRefreshButton" src="/assets/images/refresh.png" alt="Yenile" onClick={refreshMessage}  /></h2>
              <div className="chatSectionWrapper">

                <div className="chatContacts">
                  <ContactList contactList={contactListData} getContactList={getContactList}  selectedId={selectedId}  />
                </div>
                <div className="chatMessages">
                  <div className="messagesWrapper">
                      <MessageList data={messageListData} luid={xprops.login.User.UserID} />
                      <div className="messageListFooter">
                      <span className={`textCountWrapper ${charLimit >= 250 && "limit"}`}>Kalan karakter : {charLimit} / 250</span>
                        <div className="messageTextWrapper">
                            <input ref={messageRef} type="text" placeholder="Mesaj giriniz." className="edits" value={chatText} onChange={handleChangeText} maxLength={250}  />
                            <button className="btnSend" onClick={sendMessage}>Gönder</button>
                        </div>
                        
                      </div>
                  </div>
                </div>
                
              </div>                
            </div>
          </div>
      </Popup>         
  );

};

export default ChatPopup;