import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import MediaQuery from 'react-responsive';
import { slide as Menu } from 'react-burger-menu';
//import {useSelector} from "react-redux";
import * as actionCreators from '../../redux/login/login.actions';
import { ProjectActionTypes } from '../../redux/project/project.types';
import { customShowLoadingLayer,customHideLoadingLayer,showWarningLayer, hideWarningLayer } from '../../utils/utils';
import LoginPopup from '../loginpopup/loginpopup.component';
import ChatPopup from '../chatpopup/chatpopup.component';
import { Link } from 'react-router-dom';
import { project } from '../../utils/project';
import * as actionDefinitionCreators from '../../redux/definitions/definitions.actions';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import bilgilerim from '../../assets/images/bilgilerim.svg';
import './navbarsub.styles.scss';



const NavBarSub = ({menutype,pageType,returnPage,articlesData,location,url}) => {
  const xprops = useSelector(state => state);
  const dispatch = useDispatch();
  let history = useHistory();
  const [menuOpenState, setMenuOpenState] = useState({menuOpen: false});

  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);



  const handleClose = () => {
    history.push("/home");
  }
  const handleBack = () => {
    history.push("/arama-sonuclari"+returnPage);
  }  

  const handleOpenLogin = () => dispatch(actionCreators.setFormLoginStatus(true));

  const openChatWindow = () => dispatch(actionDefinitionCreators.setFormChatStatus(true));  

  const logOut = () => dispatch(actionCreators.userLogout());
  const onWarning = (payload) => {
    dispatch({ type: ProjectActionTypes.SET_WARNING_MESSAGE, payload: payload});
  }

  const handleLogout = (event) => {
    customShowLoadingLayer();  
    onWarning({warningMessageType:'success', warningMessageText: 'Çıkış yapıldı.'});
    showWarningLayer();
    setTimeout(() => {
      logOut();
      customHideLoadingLayer();  
      hideWarningLayer();
    },3000);
  } 

  useEffect(() => {
    
    
  }, [])



  const handleStateChange = (state) => {
    setMenuOpenState({menuOpen: state.isOpen});  
  }  

  const closeMenu = () => {
    setMenuOpenState({menuOpen: false});
  }

  const toggleMenu = () => {
    setMenuOpenState(state => ({menuOpen: !state.menuOpen}));
  }  

  return(
    <React.Fragment>
      <nav id="navsubID" className="navsubbarhome">
      <MediaQuery maxWidth={768}>          
        <ul className="navsub-menu-sub-left">
        {
          pageType === "articles" ?
            <li className="navsub-item-sub-left articleMenuWrapper">
              <div id="article-menu-outer-container">            
                <Menu 
                  left 
                  isOpen={menuOpenState.menuOpen}
                  onStateChange={(state) => handleStateChange(state)}
                  width={'280px'} 
                  pageWrapId={ "mainID" } 
                  outerContainerId={ "article-menu-outer-container" }
                  customBurgerIcon={ <img src="/assets/images/menu-makale.png" alt="Blog" /> } 
                  customCrossIcon={ <img src="/assets/images/menu-toggle-off.png" alt="Gizle" /> }
                >
                  {
                    articlesData !== null && articlesData.articles.length > 0 &&
                      articlesData.articles.map((currentArticle,index) => {
                        let selected = "";
                        if (location.pathname === "/blog/" || location.pathname === "/blog"){
                          if (index === 0){
                            selected = "selected";
                          }
                        }else{
                          selected = location.pathname.includes(currentArticle.slug) ? 'selected' : '';
                        }
                        return(
                          <Link className={`bm-item ${selected}`} to={`${url}/${currentArticle.slug}`} onClick={closeMenu}>{currentArticle.title}</Link>
                        )
                      })
                  }
                </Menu>
              </div>
            </li>
          : 
            <li className="navsub-item-sub-left">&nbsp;</li>
        }
          <li className="navsub-item-sub-left">&nbsp;</li>
        </ul>
      </MediaQuery>
      <ul className='nav-menu-sub-left'>
      {
        xprops.login.isLoggedIn === true &&
         <React.Fragment>
          <li className="nav-item-sub-left">Hoşgeldiniz! | <button className="btnSignout" onClick={handleLogout}>Çıkış</button></li>           
         </React.Fragment>
      }
      </ul>      
        <div className="navsubbarhome-container">
          <Link to="/" className="logoContainer">
            <img className="navsubbarhome-logo" src={process.env.PUBLIC_URL + '/assets/images/logo.png'} alt={project.projectName} />
          </Link>        
        </div>
        <ul className="navsub-menu-sub">
        {
          xprops.login.isLoggedIn === true ?
           <React.Fragment>
           <li className="nav-item-sub linkToLogin" onClick={() => openChatWindow()}><img className="img-mesajlar" src={process.env.PUBLIC_URL + '/assets/images/messages-on.png'} alt={xprops.project.language.keywords.messages} /></li>
           <li className="nav-item-sub linka linkToLogin" onClick={() => openChatWindow()}>{xprops.project.language.keywords.messages}</li>

            <li className="nav-item-sub"><Link to='/profil'><img className="img-bilgilerim" src={bilgilerim} alt={xprops.project.language.keywords.profile} /></Link></li>
            <li className="nav-item-sub"><Link to='/profil' className="linka">{xprops.project.language.keywords.profile}</Link></li>
           </React.Fragment>
          : 
          <React.Fragment>
            <li className="nav-item-sub linkToLogin" onClick={() => handleOpenLogin()}><img className="img-bilgilerim" src={bilgilerim} alt={xprops.project.language.keywords.profile} /></li>
            <li className="nav-item-sub linkToLogin" onClick={() => handleOpenLogin()}>{xprops.project.language.keywords.signIn}</li>
          </React.Fragment>
        }

        
        {
          pageType === "expert" ? 
            <li className="nav-item-sub"> <button className="btnCloseSubPage" onClick={handleBack} ><img src="/assets/images/back-button.png" alt="" className="closeSignUp" /></button></li>
            :
            <li className="nav-item-sub"> <button className="btnCloseSubPage" onClick={handleClose} ><img src="/assets/images/close-button.png" alt="" className="closeSignUp" /></button></li>
        }

        {
          // Header 
        }
        
        </ul>
      </nav>
      <LoginPopup formOpenStatus={xprops.login.formLoginOpenStatus} loginFormType="medya"/> 
      {
        xprops.login.isLoggedIn === true && 
        <ChatPopup fullname="" />
      }

    </React.Fragment>
  )
};


export default NavBarSub;

