import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Select from "react-dropdown-select";
import CheckBox from '../../components/checkbox/checkbox.component';
import Button from '../../components/button/button.component';

import { ProjectActionTypes } from '../../redux/project/project.types';
import { DefinitionsActionTypes } from '../../redux/definitions/definitions.types';

import { showWarningLayer } from '../../utils/utils';

import {countries} from '../../utils/countries';
// import {regions} from '../../utils/regions';
import {experties} from '../../utils/experties';

import '../../App.css';
import './step2.styles.scss';

class Step2 extends React.Component{

  constructor(props){
    super(props);
    this.state = {
      topicName:'',
      topics: (this.props.step2.topics === null || this.props.step2.topics === undefined || this.props.step2.topics === []) ? [] : this.props.step2.topics,

      checkboxes: experties.reduce(
        (options, option) => ({
          ...options,
          [option]: (this.props.step2.experties !== undefined && this.props.step2.experties !== null && this.props.step2.experties.length > 0 && this.props.step2.experties.includes(option)) ? true : false
        }),
        {}
      )      
    };
    this.handleTopicChange = this.handleTopicChange.bind(this);
    this.addToTopics = this.addToTopics.bind(this);
    this.removeItem = this.removeItem.bind(this);

    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.props.setStep2({
      ...this.props.step2,
      expertiesError: false,
      topicsError: false,
      countriesError: false,
      regionsError: false
    });    
    
  }


  handleTopicChange(event){
    this.setState({
      ...this.state,
      topicName: event.target.value
    })
  }  


  handleCheckboxChange = changeEvent => {
    const { name } = changeEvent.target;
    this.setState(prevState => ({
      checkboxes: {
        ...prevState.checkboxes,
        [name]: !prevState.checkboxes[name]
      }
    }),()=> {
        let tmpArr = [];  
        Object.entries(this.state.checkboxes).filter((obj)=>{
          return obj[1] === true && tmpArr.push(obj[0])
          }
        );
        this.props.setStep2({
          ...this.props.step2,
          experties: tmpArr
        }); 
    });
  };

  addToTopics(){
    let newLink = this.state.topicName;
    if (newLink.length < 3){
      const warnmessage = "Lütfen konu başlığını kontrol edin.";
      this.props.onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
      showWarningLayer();
      return;
    }

    const newArray = this.state.topics.slice();    
    newArray.push(newLink);

    this.setState({
      ...this.state,
      topics: newArray,
      topicName:''
    });

    this.props.setStep2({
      ...this.props.step2,
      topics:newArray
    })      
  }

  removeItem(item,index){
    const newList = [...this.state.topics];
    var tmpIndex = newList.indexOf(item);
    if (tmpIndex !== -1){
      newList.splice(index,1);
      this.setState({
        ...this.state,
        topics: newList
      })
      this.props.setStep2({
        ...this.props.step2,
        topics:newList,
      })      

    }
  }



  componentDidMount(){
    this.props.setCurrent('step-2');
  } 
  render(){
    return(
      <section className="mainSection mainSection2 mainSectionWrapper">
        <div className="sectionWrapper">
          <div className="gridWrapper">
            <div>
              <h4 className={`sectionTitle`}>Uzmanlık Alanlarınız</h4>
              <div className="componentWrapper step2-layer1">
                  <div className="contentInfoWrapper">
                    {
                      experties.map((item)=>{
                        return(
                         <CheckBox name={item} key={item} value={item} defaultChecked={this.state.checkboxes[item]} checked={this.state.checkboxes[item]} onChange={this.handleCheckboxChange} >{item}</CheckBox>
                        )
                      })
                    }


                  </div>
              </div>
            </div>
            <div>
              <h4 className={`sectionTitle`}>Görüş Vermek İstediğiniz Konular</h4>
              <div className="baglantiWrapper">
                <input type="text" placeholder="Konu başlığı girin" className="edits" value={this.state.topicName} onChange={this.handleTopicChange} />
                <Button onClick={this.addToTopics}>Ekle</Button>
              </div>

              <div className="componentWrapper step2-layer2">
                <div className="contentInfoWrapper gorus-konular gorus-konular-step2">
                  <ul className="ulYayinReferanslar">
                  {
                    this.state.topics.map((item,index)=>(
                      <li key={index} title={item}><button className="btn-remove-list" onClick={() => this.removeItem(item,index)} ></button><span>{item}</span></li>
                    ))
                  }
                  </ul>
                </div>

              </div>
            </div>
            <div>
              <h4 className={`sectionTitle`}>Uzman Olduğunuz Ülkeler</h4>
              <div className="componentWrapper step2-layer3">
                <div className="contentInfoWrapper gorus-konular">

                  <Select
                    multi
                    create
                    placeholder="Lütfen ülke seçiniz."
                    addPlaceholder="ülke seçiniz."
                    createNewLabel="Ekle"
                    options={countries}
                    values={this.props.step2.countries}
                    labelField="name"
                    valueField="name"
                    color="#ff004c"
                    onChange={(value) => {
                      this.props.setStep2({
                        ...this.props.step2,
                        countries: value
                      })
                        //console.log(this.props.step2.countries);
                      }

                     

                    }
                  />
                 
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>
      
    );
  }
}

const mapStateToProps = state => {
  return {
    step2: state.definitions.step2,
    warningMessage: state.project.warningMessageText,
    warningType: state.project.warningMessageType,
    currentPage: state.project.currentPage
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onWarning: (payload) => dispatch({ type: ProjectActionTypes.SET_WARNING_MESSAGE, payload: payload}),
    setDefault: () => dispatch({ type: ProjectActionTypes.SET_DEFAULT}),
    setStep2: (step2) => dispatch({ type: DefinitionsActionTypes.SET_STEP2, step2: step2}),
    setCurrent: (value) => dispatch({type: ProjectActionTypes.SET_CURRENT, value: value })
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Step2));