import React from 'react';
import ReactGA from 'react-ga';
import { 
  FASTDesignSystemProvider,
} from '@microsoft/fast-components';
import WarningLayer from './components/warninglayer/warninglayer.component';
import LoadingLayer from './components/loadinglayer/loadinglayer.component';
import { hideWarningLayer } from './utils/utils';
import { language } from './utils/lang.js';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';

import Home from './pages/home/home.page.component';
import SignUp from './pages/signup/signup.page.component';
import MedyaKayit from './pages/medyakayit/medyakayit.page.component';
import Cvs from './pages/cvs/cvs.page.component';
import FAQ from './pages/faq/faq.page.component';
import Articles from './pages/articles/articles.page.component';
import TanitimFilmi from './pages/promotionfilm/promotionfilm.component';
import SearchResultsPage from './pages/searchresults/searchresults.page.component';
import ExpertPage from './pages/expert/expert.page.component';
import ProfilePage from './pages/profile/profile.page.component';
import ResetPasswordPage from './pages/resetpassword/resetpassword.page.component';
import { ProjectActionTypes } from './redux/project/project.types';
import './App.css';
import './res.scss';
import './host.css';

const TRACKING_ID = "G-QM43RB6M7H"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);
const baseUrl = "https://www.sessizolmaz.org/"

class App extends React.Component {
  
  UNSAFE_componentWillMount(){
    if (this.props.language === null){
      const defaultLang = language.default;
      const tmpLang = language[defaultLang];
      this.props.setLanguage(tmpLang);
    }else{
      const defaultLang = this.props.language.short;
      const tmpLang = language[defaultLang];
      this.props.setLanguage(tmpLang);
    }
    this.getLatestPost();
  }

  async getLatestPost(){
    let res = await axios.get(baseUrl+"get-latest-blog");
    let data = res.data;
    this.props.setLatestPosts(data);
  }  
  

  render(){
    return (
      <fast-design-system-provider use-defaults>      
      <main id="mainID" className={`${this.props.parentPage === 'signup' ? 'signup' : this.props.parentPage === 'medyakayit' ? 'signup medyakayit' : this.props.parentPage === 'video' ? 'video' : 'home'} ${this.props.currentPage === 'baslangic' ? "mainBaslangic" : ""} ${this.props.currentPage === 'baslangicmedya' ? "mainBaslangic" : ""}`}>
        <Router>
          
          <Switch>
            <Route exact path='/home' component={Home} />
            <Route path="/signup">
              <SignUp />
            </Route>            
            <Route path="/ozgecmisler/:people">
              <Cvs />
            </Route>                        
            <Route path="/ozgecmisler">
              <Cvs />
            </Route>              
            <Route path="/sss">
              <FAQ />
            </Route>
            <Route path="/blog">
              <Articles />
            </Route>
            <Route path="/tanitim-filmi">
              <TanitimFilmi />
            </Route>
            <Route path="/arama-sonuclari">
              <SearchResultsPage />
            </Route>
            <Route path="/uzman/:eid/:ename">
              <ExpertPage />
            </Route>
            <Route path="/profil">
              <ProfilePage />
            </Route>
            <Route path="/sifre-resetle">
              <ResetPasswordPage />
            </Route>
            <Route path="/resetpassword">
              <ResetPasswordPage />
            </Route>
            <Route path="/medyakayit">
              <MedyaKayit />
            </Route>            

            <Route>
              <Redirect to="/home" />
            </Route>          
            

          </Switch>
        </Router>
        <LoadingLayer showLoading={this.props.showLoading} />
        <WarningLayer hideWarning={hideWarningLayer} text={this.props.warningMessage} warningType={this.props.warningType} />
      </main>
      </fast-design-system-provider>
    );
  }
}

const mapStateToProps = state => {
  return {
    warningMessage: state.project.warningMessageText,
    warningType: state.project.warningMessageType,
    currentPage: state.project.currentPage,
    parentPage: state.project.parentPage,
    pageload: state.project.pageload,
    showLoading: state.project.showLoading,
    language: state.project.language,
    latestPosts: state.project.latestPosts

  }
}
const mapDispatchToProps = dispatch => {
  return {
    setLoading: (showLoading) => dispatch({ type: ProjectActionTypes.SET_LOADING, showLoading: showLoading}),
    setLanguage: (language) => dispatch({ type: ProjectActionTypes.SET_LANGUAGE, language: language}),
    setParent: (value) => dispatch({ type: ProjectActionTypes.SET_PARENT, value: value}),
    setLatestPosts: (value) => dispatch({ type: ProjectActionTypes.SET_POSTS, value: value})
  }
};

export default connect(mapStateToProps,mapDispatchToProps)(App);

