import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ProjectActionTypes } from '../../redux/project/project.types';
import { DefinitionsActionTypes } from '../../redux/definitions/definitions.types';
import '../../App.css';
import './step4.styles.scss';
import RadioButton from '../../components/radiobutton/radiobutton.component';
import Button from '../../components/button/button.component';
import { showWarningLayer } from '../../utils/utils';

class Step4 extends React.Component{

  constructor(props){
    super(props);
    this.state = {
      baglantiAdi:'',
      links: (this.props.step4.links === null || this.props.step4.links === undefined || this.props.step4.links === []) ? [] : this.props.step4.links
    };
    this.handleMedyaVarmi = this.handleMedyaVarmi.bind(this);
    this.handleKonferansVarmi = this.handleKonferansVarmi.bind(this);
    this.handleBaglantiChange = this.handleBaglantiChange.bind(this);
    this.addToLinks = this.addToLinks.bind(this);
    this.removeItem = this.removeItem.bind(this);
  }

  handleMedyaVarmi(event){
    this.props.setStep4({
      ...this.props.step4,
      medyavarmi: event.target.value,
    });
  }  

  handleKonferansVarmi(event){
    this.props.setStep4({
      ...this.props.step4,
      konferansvarmi: event.target.value,
    });
  }    

  handleBaglantiChange(event){
    this.setState({
      ...this.state,
      baglantiAdi: event.target.value
    })
  }

  addToLinks(){
    let newLink = this.state.baglantiAdi;
    if (newLink.length < 10){
      const warnmessage = "Lütfen geçerli bir bağlantı girin.";
      this.props.onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
      showWarningLayer();
      return;
    }

    const newArray = this.state.links.slice();    
    newArray.push(newLink);

    this.setState({
      ...this.state,
      links: newArray,
      baglantiAdi:''
    });

    this.props.setStep4({
      ...this.props.step4,
      links:newArray
    })      
  }

  removeItem(item,index){
    const newList = [...this.state.links];
    var tmpIndex = newList.indexOf(item);
    if (tmpIndex !== -1){
      newList.splice(index,1);
      this.setState({
        ...this.state,
        links: newList
      })
      this.props.setStep4({
        ...this.props.step4,
        links:newList,
      })      

    }
  }



  componentDidMount(){
    this.props.setCurrent('step-4');
  } 
  render(){
    return(
      <section className="mainSection mainSectionWrapper">
        <div className="sectionWrapper">
          <div className="gridWrapper">
            <div>
              <h4 className="sectionTitle">Medya deneyiminiz var mı?</h4>
              <div className="medyaDeneyimiRadioWrapper">
                <div name="medyaDeneyimi" className="medyaDeneyimiRadioGroup" onChange={this.handleMedyaVarmi}>
                  <RadioButton name="checkMedyaDeneyimi" defaultCheck={this.props.step4.medyavarmi === "1" ? true : false} value="1">Var</RadioButton>
                  <RadioButton name="checkMedyaDeneyimi" defaultCheck={this.props.step4.medyavarmi === "0" ? true : false} value="0">Yok</RadioButton>
                </div>              
              </div>
            </div>
            <div>
              <h4 className="sectionTitle">Konferans deneyiminiz var mı?</h4>
              <div className="konferansDeneyimiRadioWrapper">
                <div name="konferansDeneyimi" className="medyaDeneyimiRadioGroup" onChange={this.handleKonferansVarmi}>
                  <RadioButton name="checkKonferansDeneyimi" defaultCheck={this.props.step4.konferansvarmi === "1" ? true : false} value="1">Var</RadioButton>
                  <RadioButton name="checkKonferansDeneyimi" defaultCheck={this.props.step4.konferansvarmi === "0" ? true : false} value="0">Yok</RadioButton>
                </div>              

              </div>
            </div>
            <div>
              <h4 className="sectionTitle">İlgili yayın ve referans bağlantıları</h4>
              <sub className="linkInfo">Buraya yayın, youtube bağlantısı ekleyebilirsiniz.</sub>
              <div className="baglantiWrapper">
                <input type="text" placeholder="Bağlantı adresi girin" className="edits" value={this.state.baglantiAdi} onChange={this.handleBaglantiChange} />
                <Button onClick={this.addToLinks}>Ekle</Button>
              </div>
              <div className="componentWrapper step4-layer1">
                <div className="contentInfoWrapper gorus-konular step4GorusKonular">
                  <ul className="ulYayinReferanslar">
                  {
                    this.state.links.map((item,index)=>(
                      <li key={index} title={item}><button className="btn-remove-list" onClick={() => this.removeItem(item,index)} ></button><span>{item}</span></li>
                    ))
                  }
                  </ul>
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>
      
    );
  }
}

const mapStateToProps = state => {
  return {
    step4: state.definitions.step4,
    warningMessage: state.project.warningMessageText,
    warningType: state.project.warningMessageType,
    currentPage: state.project.currentPage
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onWarning: (payload) => dispatch({ type: ProjectActionTypes.SET_WARNING_MESSAGE, payload: payload}),
    setDefault: () => dispatch({ type: ProjectActionTypes.SET_DEFAULT}),
    setStep4: (step4) => dispatch({ type: DefinitionsActionTypes.SET_STEP4, step4: step4}),
    setCurrent: (value) => dispatch({type: ProjectActionTypes.SET_CURRENT, value: value })
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Step4));