import React, { useState,useEffect,useRef } from 'react';
import { useLocation, useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { ProjectActionTypes } from '../../redux/project/project.types';
import { DefinitionsActionTypes } from '../../redux/definitions/definitions.types';
import { LoginActionTypes } from '../../redux/login/login.types';
import * as actionCreators from '../../redux/login/login.actions';
import { SocialIcon } from 'react-social-icons';
import Button from '../../components/button/button.component';
import {Select as Selectx} from "react-dropdown-select";
import Select,{components }  from "react-select";
import CheckBox from '../../components/checkbox/checkbox.component';
import RadioButton from '../../components/radiobutton/radiobutton.component';
import PhoneInput from 'react-phone-number-input/input';
import ImgCrop from 'antd-img-crop';
import { Upload, Progress } from 'antd';

import axios from 'axios';

import NavBarSub from '../../components/navbarsub/navbarsub.component';
import { customShowLoadingLayer,customHideLoadingLayer,showWarningLayer, hideWarningLayer } from '../../utils/utils';
import {countries} from '../../utils/countries';
import {experties} from '../../utils/experties';

import 'reactjs-popup/dist/index.css';
import './profile.page.styles.scss';

const API_URL = process.env.REACT_APP_API_URL; 

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

const ProfilePage = () => {
  const [mounted, setMounted] = useState(false);
  const xprops = useSelector(state => state);
  const dispatch = useDispatch();
  const history = useHistory();

  const setParent = (value) => dispatch({type: ProjectActionTypes.SET_PARENT, value: value });  
  if(!mounted){
    setParent("profile");  
  }
  const handleOnChange = ({ file, fileList, event }) => {
    //console.log(fileList);
    setState({
      ...state,
      defaultFileList: fileList
    });   
    setPicture({
      ...xprops.definitions.picture,
      defaultFileList: fileList
    });
  };

  
  if (xprops.login.isLoggedIn === false){
    history.push("/home");
  }    
  
  //const url = "?page="+xprops.project.searchQueryParams.page+"&keyword="+xprops.project.searchQueryParams.keyword+"&profession="+xprops.project.searchQueryParams.profession;
  const url = "/home";


  const[state, setState] = useState(    
    {
      isLoaded:false,
      checkboxes:[],
      defaultFileList:[],
      progress:0,
      topics:[],
      profilePic:"",
      ext:"",
      fullname:"",
      socialPrefix:'https://www.facebook.com/'
    }
  );

  useEffect(() => {
    let tmpArr = [];  
    try{
      Object.entries(state.checkboxes).filter((obj)=>{
        return obj[1] === true && tmpArr.push(obj[0])
        }
      );
    }catch(ex){}
    setState({
      ...state,
      uzmanlikalanlari: tmpArr
    }); 
       //console.log(state.defaultFileList);
  }, [state.checkboxes]);

  // useEffect(() => {
  //   console.log(state.defaultFileList);
  // }, [state.defaultFileList]);  

  const setPicture = (picture) => dispatch({ type: DefinitionsActionTypes.SET_PICTURE, picture: picture});
  
  const logOut = () => dispatch(actionCreators.userLogout());

  const onWarning = (payload) => {
    dispatch({ type: ProjectActionTypes.SET_WARNING_MESSAGE, payload: payload});
  }

  const handleChange = (evt) => {
    const value = evt.target.type === "checkbox" ? evt.target.checked : evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value
    });
  }  

  const handlePhoneChange = (name,value) => {
    //console.log(value);
    setState({
      ...state,
      [name]: value
    });
  }    


  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };  

  //Upload Image
  const uploadImage = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;
    const fmData = new FormData();
    const config = {
      headers: { "content-type": "multipart/form-data" },
      onUploadProgress: event => {
        const percent = Math.floor((event.loaded / event.total) * 100);
        setState({
          ...state,
          progress: percent
        });          
        if (percent === 100) {
          setTimeout(() => setState({...state,progress: 0}), 1000);
        }
        onProgress({ percent: (event.loaded / event.total) * 100 });
      }
    };
    fmData.append("image", file);
    try {
      const res = await axios.post(
        "https://www.sensizolmazsessizolmaz.org/uploadimage.php",
        fmData,
        config
      );
      let warnmessage = "";
      let profilePic = "";
      let ext = "";
      if (res.data.result === "ok"){
        profilePic = res.data.fileid;
        ext = res.data.ext;
        warnmessage = "Resim yüklendi.";
        onSuccess("Ok");
        onWarning({warningMessageType:'success', warningMessageText: warnmessage});      
        showWarningLayer();
      }else{
        ext = "";
        warnmessage = "Bir sorun oluştu. Lütfen tekrar deneyiniz.";
        const error = new Error("Some error");
        onError({ error });        
        onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
        showWarningLayer();
      }

      setState({
        ...state,
        progress: 0,
        profilePic: profilePic !== "" ? profilePic+"."+ext : "",
        ext: ext
      }); 

      setPicture({
        ...xprops.definitions.picture,
        profilePic: profilePic !== "" ? profilePic+"."+ext : "",
        ext: ext
      });
      
    } catch (err) {
      let warnmessage = "Bir sorun oluştu. Lütfen tekrar deneyiniz.";
      const error = new Error("Some error");
      onError({ error });        
      onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
      showWarningLayer();      
    }
  };  

  //Remove Image
  const removeImage = async () => {
    const fmData = new FormData();
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    fmData.append("pid", state.profilePic);
    fmData.append("ext", state.ext);
    try {
      const res = await axios.post(
        "https://www.sensizolmazsessizolmaz.org/removeimage.php",
        fmData,
        config
      );
      
      let warnmessage = "";
      if (res.data.result === "ok"){
        setState({
          ...state,
          progress: 0,
          profilePic: "",
          defaultFileList:[],
          ext: ""
        });     
        
        setPicture({
          ...xprops.definitions.picture,
          profilePic: "",
          ext: ""
        });        

        warnmessage = "Resim kaldırıldı.";
        onWarning({warningMessageType:'success', warningMessageText: warnmessage});      
        showWarningLayer();
      }else{
        warnmessage = "Bir sorun oluştu. Lütfen tekrar deneyiniz.";
        onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
        showWarningLayer();
      }
    } catch (err) {
      let warnmessage = "Bir sorun oluştu. Lütfen tekrar deneyiniz.";
      onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
      showWarningLayer();
    }    
  }

  

  const handleCheckboxChange = changeEvent => {
    const { name } = changeEvent.target;
    setState({
      ...state,
      checkboxes:{
        ...state.checkboxes,
        [name]:!state.checkboxes[name]
      },
    });
  };


  const addToTopics = () => {
    let newLink = state.topicname;
    if (newLink !== null && newLink !== undefined && newLink.length < 3){
      const warnmessage = "Lütfen konu başlığını kontrol edin.";
      onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
      showWarningLayer();
      return;
    }
    const newArray = state.gorusverilecekkonular.slice();    
    newArray.push(newLink);
    setState({
      ...state,
      gorusverilecekkonular: newArray,
      topicname:''
    });
  }

  const removeTopic = (item,index) =>{
    const newList = [...state.gorusverilecekkonular];
    var tmpIndex = newList.indexOf(item);
    if (tmpIndex !== -1){
      newList.splice(index,1);
      setState({
        ...state,
        gorusverilecekkonular: newList
      })
    }
  }  


  const handleBagimsizmi = (target) => {
    let tmpCheck = false;
    if (target.checked === true){
      tmpCheck = true;
      setState({
        ...state,
        calistigikurum: "",
        bagimsizmi: tmpCheck
      });
    }else{
      setState({
        ...state,
        calistigikurum: "",
        bagimsizmi: tmpCheck
      });      
    }
  }

  const handleMedyaVarmi = (event) => {
    setState({
      ...state,
      medyadeneyimivarmi: event.target.value,
    });
  }   

  const handleKonferansVarmi = (event) =>{
    setState({
      ...state,
      konferansdeneyimivarmi: event.target.value,
    });
  }   


  const addToLinks = () => {
    let newLink = state.baglantiadi;
    if (newLink !== null && newLink !== undefined && newLink.length < 10){
      const warnmessage = "Lütfen geçerli bir bağlantı girin.";
      onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
      showWarningLayer();
      return;
    }
    const newArray = state.yayinvereferanslar.slice();    
    newArray.push(newLink);
    setState({
      ...state,
      yayinvereferanslar: newArray,
      baglantiadi:''
    });

  }

  const removeLink = (item,index) => {
    const newList = [...state.yayinvereferanslar];
    var tmpIndex = newList.indexOf(item);
    if (tmpIndex !== -1){
      newList.splice(index,1);
      setState({
        ...state,
        yayinvereferanslar: newList
      })
    }
  }


  const options = [
    { custom: true, typex:"facebook", link:"https://www.facebook.com/", label:"Facebook", value:"facebook" },
    { custom: true, typex:"twitter", link:"https://twitter.com/",label:"Twitter", value:"twitter"  },
    { custom: true, typex:"instagram", link:"https://www.instagram.com/", label:"Instagram", value:"instagram"  },
    { custom: true, typex:"linkedin", link:"https://www.linkedin.com/in/", label:"LinkedIn", value:"linkedin"  },
    { custom: true, typex:"youtube", link:"https://www.youtube.com/channel/", label:"YouTube", value:"youtube"  }
  ];   
  

  const formatOptionLabel = ({ typex }) => (
    <SocialIcon network={typex} className="socialMediaIcon" style={{ height: 30, width: 30 }} />
  );  

  const CustomOption = props => {
    const { data, innerRef, innerProps } = props;
    return data.custom ? (
      <div className="socialSelectWrapper" ref={innerRef} {...innerProps}>
        <SocialIcon className="socialMediaIcon" network={data.typex} style={{ height: 30, width: 30 }}  /> 
      </div>
    ) : (
      <components.Option {...props} />
    );
  }; 

  const handleSocialChange = (event) => {
    setState({
      ...state,
      socialPrefix: event.link
    });    
  }



  const addToSocials = () => {
    let newLink = state.sociallink;
    if (newLink.length < 2){
      const warnmessage = "Lütfen geçerli bir bağlantı adresi girin.";
      onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
      showWarningLayer();
      return;
    }


    newLink = state.socialPrefix === undefined || state.socialPrefix === null || state.socialPrefix === "" ? "https://www.facebook.com/" + newLink : state.socialPrefix + newLink;
    const newArray = state.sosyalmedyahesaplari.slice();  
    if (newArray.indexOf(newLink) === -1) {
      newArray.push(newLink);
    }
    setState({
      ...state,
      sosyalmedyahesaplari: newArray,
      sociallink:''
    });
  }

  const removeSocial = (item,index) => {
    const newList = [...state.sosyalmedyahesaplari];
    var tmpIndex = newList.indexOf(item);
    if (tmpIndex !== -1){
      newList.splice(index,1);
      setState({
        ...state,
        sosyalmedyahesaplari: newList
      })
    }
  }

  const handleTableTicks = changeEvent => {
    const { name } = changeEvent.target;
    
    if (changeEvent.target.checked === true){
      setState({
        ...state,
        [name]:true
      });
    }else{
      setState({
        ...state,
        [name]:false
      });
    }
  };


  const handleGizliTercihler = changeEvent => {
    const { name } = changeEvent.target;
    const newArray = state.gizliplatformlar.slice();    

    if (changeEvent.target.checked === true){
      if (newArray.indexOf(name) === -1) {
        newArray.push(name);
      }    
      setState({
        ...state,
        gizliplatformlar: newArray,
        [name]: true
      });
    }else{
      const newList = [...state.gizliplatformlar];
      var tmpIndex = newList.indexOf(name);
      if (tmpIndex !== -1){
        newList.splice(tmpIndex,1);
        setState({
          ...state,
          gizliplatformlar: newList,
          [name]: false
        })
      }
  
    }
  };   


  const handleGizliTelefonChange = (value) =>{
    setState({
      ...state,
      gizlitelefon:value
    });
  }

  const handleProfildeTelefonChange = (value) =>{
    setState({
      ...state,
      profildetelefon:value
    });
  }  

  const handleTelefonChange = (value) =>{
    setState({
      ...state,
      telefon:value
    });
  }    


  const handleGizliEmailChange = (event) =>{
    setState({
      ...state,
      gizlieposta:event.target.value
    })    
  }    

  const handleGizliEmailCheck = changeEvent => {
    if (changeEvent.target.checked === true){
      setState({
        ...state,
        gizliemailcheck:true
      })    
    }else{
      setState({
        ...state,
        gizliemailcheck:false
      })      
    }
  };   

  const handleStatus = (event) =>{
    setState({
      ...state,
      status: event.target.value,
    });
  }   

  useEffect(() => {
    //console.log(state.gizliplatformlar)
  }, [state.gizliplatformlar])


  useEffect(()=>{
    if (xprops.login.isLoggedIn === true){
      customShowLoadingLayer();
      //console.log(xprops);
      console.log(xprops.login);
      setMounted(true);
      getProfile();
    }else{
      history.push("/home");
      customHideLoadingLayer();
    }
  },[]);

  const countOccurences = (string, word) => {
    return string.split(word).length - 1;
 }

  const getProfile = async () => {
    customShowLoadingLayer();   

    const fmData = new FormData();
    const config = {
      headers: { "content-type": "multipart/form-data" },
      crossDomain: true
    }

    fmData.append("uid", xprops.login.User.UserID);    
    fmData.append("utype", xprops.login.User.ViewType);    

    
    let warnmessage = "";
    const res = await axios.post(
      API_URL+"getprofile",
      fmData,
      config
    );
    
    if (res.data.type === "done"){
      //setUserDetails(res.data.data[0]);
      
      const currentTimeInSeconds=Math.floor(Date.now()/1000); 
      let profilepic = "";
      let ext = "";
      let defaultFileList = [];
      try{
         profilepic = (res.data.data[0].EXT === "" || res.data.data[0].EXT === null || res.data.data[0].EXT === undefined) ? "" : res.data.data[0].DETAILS.USERID;
      }catch(ex){ }

      try{
        ext = (res.data.data[0].EXT === "" || res.data.data[0].EXT === null || res.data.data[0].EXT === undefined) ? "" : res.data.data[0].EXT;
      }catch(ex){}
      
      try{
        defaultFileList = (res.data.data[0].EXT === "" || res.data.data[0].EXT === null || res.data.data[0].EXT === undefined) ? [] : [{uid: '-1',name:res.data.data[0].DETAILS.USERID+"."+res.data.data[0].EXT, status: 'done', url:`https://www.sensizolmazsessizolmaz.org/uploads/${res.data.data[0].DETAILS.USERID}.${res.data.data[0].EXT}?_x=${currentTimeInSeconds}`}];
      }catch(ex){}
      
      // profilePic: (res.data.data[0].EXT === "" || res.data.data[0].EXT === null || res.data.data[0].EXT === undefined) ? "" : res.data.data[0].DETAILS.USERID,
      // ext:(res.data.data[0].EXT === "" || res.data.data[0].EXT === null || res.data.data[0].EXT === undefined) ? "" : res.data.data[0].EXT,

      setPicture({
        ...xprops.definitions.picture,
        profilePic: profilepic,
        ext: ext
      });

      if (typeof res.data.data[0] === 'undefined'){
        warnmessage = "Bir hata oluştu. Lütfen tekrar deneyiniz.";
        onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
        showWarningLayer(); 
        customHideLoadingLayer();   
        return;
      }

      if(res.data.data[0].VIEWTYPE === "kadinuzman" ){
        let uzmanlikalanlari = res.data.data[0].DETAILS.UZMANLIKALANLARI === "" ? [] : res.data.data[0].DETAILS.UZMANLIKALANLARI.split("|");
        setState({
          isLoaded:true,
          defaultFileList: defaultFileList,
          progress:0,
          profilePic: profilepic,
          ext:ext,
          extDef:res.data.data[0].EXT,
          fullname: res.data.data[0].DOCUMENTNAME,
          bagimsizmi: res.data.data[0].DETAILS.BAGIMSIZMI === "1" ? true : false,
          calistigikurum: res.data.data[0].DETAILS.CALISTIGIKURUM,
          gizlieposta: res.data.data[0].DETAILS.GIZLIEPOSTA,
          gizliplatformlar: res.data.data[0].DETAILS.GIZLIPLATFORMLAR === "" ? [] : res.data.data[0].DETAILS.GIZLIPLATFORMLAR.split("|"),
          gizlitelefon: res.data.data[0].DETAILS.GIZLITELEFON,
          gorusverilecekkonular: res.data.data[0].DETAILS.GORUSVERILECEKKONULAR === "" ? [] : res.data.data[0].DETAILS.GORUSVERILECEKKONULAR.split("|"),
          iletisimbilgilerigorunsunmu: res.data.data[0].DETAILS.ILETISIMBILGILERIGORUNSUNMU === "1" ? true : false,
          iletisimterciheposta: res.data.data[0].DETAILS.ILETISIMTERCIHEPOSTA === "1" ? true : false,
          iletisimtercihepostagoster: res.data.data[0].DETAILS.ILETISIMTERCIHEPOSTAGOSTER === "1" ? true : false,
          iletisimtercihtelefon: res.data.data[0].DETAILS.ILETISIMTERCIHTELEFON === "1" ? true : false,
          iletisimtercihtelefongoster: res.data.data[0].DETAILS.ILETISIMTERCIHTELEFONGOSTER === "1" ? true : false,
          kisaozgecmis: res.data.data[0].DETAILS.KISAOZGECMIS,
          konferansdeneyimivarmi: res.data.data[0].DETAILS.KONFERANSDENEYIMIVARMI,
          medyadeneyimivarmi: res.data.data[0].DETAILS.MEDYADENEYIMIVARMI,
          profildeeposta: res.data.data[0].DETAILS.PROFILDEEPOSTA,
          profildetelefon: res.data.data[0].DETAILS.PROFILDETELEFON,
          sosyalmedyahesaplari: res.data.data[0].DETAILS.SOSYALMEDYAHESAPLARI === "" ? [] : res.data.data[0].DETAILS.SOSYALMEDYAHESAPLARI.split("|"),
          status: res.data.data[0].DETAILS.STATUS,
          userid: res.data.data[0].DETAILS.USERID,
          uzmanaktif: res.data.data[0].DETAILS.UZMANAKTIF,
          uzmanlikalanlari: uzmanlikalanlari,
          uzmanolunanbolgeler: res.data.data[0].DETAILS.UZMANOLUNANBOLGELER,
          uzmanolunanulkeler: res.data.data[0].DETAILS.UZMANOLUNANULKELER === "" ? [] : res.data.data[0].DETAILS.UZMANOLUNANULKELER,
          website: res.data.data[0].DETAILS.WEBSITE,
          yasadigisehir: res.data.data[0].DETAILS.YASADIGISEHIR,
          yasadigiulke: res.data.data[0].DETAILS.YASADIGIULKE === "" ? [] : res.data.data[0].DETAILS.YASADIGIULKE,
          yayinvereferanslar: res.data.data[0].DETAILS.YAYINVEREFERANSLAR === "" ? [] : res.data.data[0].DETAILS.YAYINVEREFERANSLAR.split("|"),
          documentid: res.data.data[0].DOCUMENTID,
          documenttitle: res.data.data[0].DOCUMENTTITLE,
          documentname: res.data.data[0].DOCUMENTNAME,
          fileid: res.data.data[0].FILEID,
          checkboxes: experties.reduce(
            (options, option) => ({
              ...options,
              [option]: (uzmanlikalanlari !== undefined && uzmanlikalanlari !== null && uzmanlikalanlari.length > 0 && uzmanlikalanlari.includes(option)) ? true : false
            }),
            {}
          ),
          gizlitercihsms: res.data.data[0].DETAILS.GIZLIPLATFORMLAR.indexOf("gizlitercihsms") > -1 ? true : false,
          gizliterciharama: res.data.data[0].DETAILS.GIZLIPLATFORMLAR.indexOf("gizliterciharama") > -1 ? true : false,
          gizlitercihwhatsapp: res.data.data[0].DETAILS.GIZLIPLATFORMLAR.indexOf("gizlitercihwhatsapp") > -1 ? true : false,
          gizliemailcheck: res.data.data[0].DETAILS.GIZLIEPOSTA !== "" && res.data.data[0].DETAILS.GIZLIEPOSTA.length > 0 ? true : false,
          viewtype: res.data.data[0].VIEWTYPE,
        })   
      }else{
        setState({
          isLoaded:true,
          defaultFileList: defaultFileList,
          progress:0,
          profilePic: profilepic,
          ext:ext,
          extDef:res.data.data[0].EXT,
          fullname: res.data.data[0].DOCUMENTNAME,
          telefon: res.data.data[0].TELEFON,
          bagimsizmi: res.data.data[0].DETAILS.BAGIMSIZMI === "1" ? true : false,
          calistigikurum: res.data.data[0].DETAILS.CALISTIGIKURUM,
          kisaAciklama: res.data.data[0].DETAILS.KISAACIKLAMA,
          eposta: res.data.data[0].DETAILS.EPOSTA,
          status: res.data.data[0].DETAILS.STATUS,
          userid: res.data.data[0].DETAILS.USERID,
          website: res.data.data[0].DETAILS.WEBSITE,
          documentid: res.data.data[0].DOCUMENTID,
          documenttitle: res.data.data[0].DOCUMENTTITLE,
          documentname: res.data.data[0].DOCUMENTNAME,
          fileid: res.data.data[0].FILEID,
          viewtype: res.data.data[0].VIEWTYPE,
        })   
  
      }

      //console.log(state);   
      customHideLoadingLayer();   
    }else{
      if (res.data.message === "DP_Empty"){
        warnmessage = "Lütfen seçim yapınız.";
      }else{
        warnmessage = "Bir hata oluştu. Lütfen tekrar deneyiniz.";
      }
      onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
      showWarningLayer(); 
      customHideLoadingLayer();   
    }    
  }

  const saveProfile = async () => {
    customShowLoadingLayer();   
    const fmData = new FormData();
    let warnmessage = "";

    if (state.viewtype === "kadinuzman"){
      //FullName
      if (state.fullname === ""){
        warnmessage = "Lütfen ad / soyad alanını kontrol edin.";
        onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
        showWarningLayer();      
        customHideLoadingLayer();
        return;
      }else{
        fmData.append("fullname", state.fullname);
      }

      //Profile Picture
      if (xprops.definitions.picture.profilePic === ""){
        fmData.append("profilepic", "");
        fmData.append("ext", "");
      }else{
        fmData.append("profilepic",xprops.definitions.picture.profilePic);
        fmData.append("ext",xprops.definitions.picture.ext);
      }

      //step 2

      //Experties
      if (state.uzmanlikalanlari.length === 0 ){
        warnmessage = "Lütfen uzmanlık alanlarınızı seçin.";
        onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
        showWarningLayer();      
        customHideLoadingLayer();
        return;
      }else{
        fmData.append("experties",JSON.stringify(state.uzmanlikalanlari));
      }

      //Topics
      if (state.gorusverilecekkonular === undefined || state.gorusverilecekkonular === null || state.gorusverilecekkonular.length === 0 ){
        warnmessage = "Lütfen görüş vermek istediğiniz konu girin.";
        onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
        showWarningLayer();      
        customHideLoadingLayer();
        return;      
      }else{
        fmData.append("topics",JSON.stringify(state.gorusverilecekkonular));
      }

      //Countries
      if (state.uzmanolunanulkeler === undefined || state.uzmanolunanulkeler === null || state.uzmanolunanulkeler.length === 0 ){
        warnmessage = "Lütfen uzman olduğunuz ülkeleri seçin.";
        onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
        showWarningLayer();   
        customHideLoadingLayer();      
        return;
      }else{
        fmData.append("countries",JSON.stringify(state.uzmanolunanulkeler));
      }
      //step 3

      //Check for freelance & company
      if (state.bagimsizmi === undefined || state.bagimsizmi === null || state.bagimsizmi === false ){
        if (state.calistigikurum === undefined || state.calistigikurum === null || state.calistigikurum === ""){
          fmData.append("company","");  
        }else{
          fmData.append("isfree","0");  
          fmData.append("company",state.calistigikurum);  
        }
      }else{
        fmData.append("isfree","1");  
        fmData.append("company","");  
      }

      //Website
      if (state.website === undefined || state.website === null || state.website === ""){
        fmData.append("website","");  
      }else{
        fmData.append("website",state.website);  
      }

      //short cv
      if (state.kisaozgecmis === undefined || state.kisaozgecmis === null || state.kisaozgecmis === "" ){
        warnmessage = "Lütfen kısa özgeçmiş giriniz.";
        onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
        showWarningLayer();   
        customHideLoadingLayer();            
        return;
      }else{
        fmData.append("shortcv",state.kisaozgecmis);
      }

      //country 
      if (state.yasadigiulke === undefined || state.yasadigiulke === null || state.yasadigiulke === "" ){
        warnmessage = "Lütfen yaşadığınız ülkeyi seçiniz.";
        onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
        showWarningLayer();   
        customHideLoadingLayer();          
        return;
      }else{
        fmData.append("country",state.yasadigiulke[0].name);
      }    

      //city 
      if (state.yasadigisehir === undefined || state.yasadigisehir === null || state.yasadigisehir === "" ){
        warnmessage = "Lütfen yaşadığınız şehiri giriniz.";
        onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
        showWarningLayer();     
        customHideLoadingLayer();          
        return;
      }else{
        fmData.append("city",state.yasadigisehir);
      }    

      //step 4
      //konferans varmi
      if (state.konferansdeneyimivarmi === "" || state.konferansdeneyimivarmi === undefined || state.konferansdeneyimivarmi === null ){
        fmData.append("konferansvarmi", "0");
      }else{
        fmData.append("konferansvarmi",state.konferansdeneyimivarmi);
      }    

      //medya varmi
      if (state.medyadeneyimivarmi === "" || state.medyadeneyimivarmi === undefined || state.medyadeneyimivarmi === null ){
        fmData.append("medyavarmi", "0");
      }else{
        fmData.append("medyavarmi",state.medyadeneyimivarmi);
      }    

      //links
      if (state.yayinvereferanslar === undefined || state.yayinvereferanslar === null || state.yayinvereferanslar.length === 0 ){
        fmData.append("links", "");
      }else{
        fmData.append("links",JSON.stringify(state.yayinvereferanslar));
      }    
      //step 5
      //socials
      if (state.sosyalmedyahesaplari === undefined || state.sosyalmedyahesaplari === null || state.sosyalmedyahesaplari.length === 0 ){
        fmData.append("socials", "");
      }else{
        fmData.append("socials",JSON.stringify(state.sosyalmedyahesaplari));
      }        

      fmData.append("showInfo","0");
      fmData.append("phone",state.profildetelefon);
      fmData.append("email",state.profildeeposta);
      if (state.iletisimtercihtelefongoster !== null && state.iletisimtercihtelefongoster !== undefined && state.iletisimtercihtelefongoster === true ){
        fmData.append("tercihphoneshow","1");
      }else{
        fmData.append("tercihphoneshow","0");
      }
      if (state.iletisimtercihtelefon !== null && state.iletisimtercihtelefon !== undefined && state.iletisimtercihtelefon === true ){
        fmData.append("tercihphoneuse","1");
      }else{
        fmData.append("tercihphoneuse","0");
      }        
      if (state.iletisimtercihepostagoster !== null && state.iletisimtercihepostagoster !== undefined && state.iletisimtercihepostagoster === true ){
        fmData.append("tercihemailshow","1");
      }else{
        fmData.append("tercihemailshow","0");
      }
      if (state.iletisimterciheposta !== null && state.iletisimterciheposta !== undefined && state.iletisimterciheposta === true ){
        fmData.append("tercihemailuse","1");
      }else{
        fmData.append("tercihemailuse","0");
      }   
      
      if (state.gizliplatformlar !== undefined && state.gizliplatformlar !== null && state.gizliplatformlar.length !== 0 ){
        fmData.append("gizlitercihler",JSON.stringify(state.gizliplatformlar));
        //gizli gizliemailcheck
        if (state.gizlitelefon === undefined || state.gizlitelefon === null || state.gizlitelefon === "" ){
          warnmessage = "Lütfen telefon numarası giriniz.";
          onWarning({warningMessageType:'fail', warningMessageText: warnmessage});
          showWarningLayer();      
          customHideLoadingLayer();  
          return;    
        }else{
          fmData.append("gizliphone",state.gizlitelefon);
        }        
      }

      if (state.gizliemailcheck !== undefined && state.gizliemailcheck !== null && state.gizliemailcheck === true ){
        //gizli email
        if (state.gizlieposta === undefined || state.gizlieposta === null || state.gizlieposta === "" ){
          warnmessage = "Lütfen e-posta adresi giriniz.";
          onWarning({warningMessageType:'fail', warningMessageText: warnmessage});
          showWarningLayer();      
          customHideLoadingLayer();  
          return;
        }else{
          fmData.append("gizliemail",state.gizlieposta);
        }        
      }         

      fmData.append("status",state.status);
      fmData.append("userid",state.userid);
      fmData.append("documentid",state.documentid);
      fmData.append("documenttitle",state.documenttitle);
      fmData.append("fileid",state.fileid);
      fmData.append("password",xprops.login.User.Password);
      fmData.append("passwordx",xprops.login.User.Passwordx);
      fmData.append("sessionidx",xprops.login.User.SessionID);
      fmData.append("groupid",xprops.login.User.GroupID);
      fmData.append("viewtype",state.viewtype);

    }else{
      //FullName
      if (state.fullname === ""){
        warnmessage = "Lütfen ad / soyad alanını kontrol edin.";
        onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
        showWarningLayer();      
        customHideLoadingLayer();
        return;
      }else{
        fmData.append("fullname", state.fullname);
      }

      //Profile Picture
      if (xprops.definitions.picture.profilePic === ""){
        fmData.append("profilepic", "");
        fmData.append("ext", "");
      }else{
        fmData.append("profilepic",xprops.definitions.picture.profilePic);
        fmData.append("ext",xprops.definitions.picture.ext);
      }


      //Check for freelance & company
      if (state.bagimsizmi === undefined || state.bagimsizmi === null || state.bagimsizmi === false ){
        if (state.calistigikurum === undefined || state.calistigikurum === null || state.calistigikurum === ""){
          fmData.append("company","");  
        }else{
          fmData.append("freelance","0");  
          fmData.append("company",state.calistigikurum);  
        }
      }else{
        fmData.append("freelance","1");  
        fmData.append("company","");  
      }

      //Website
      if (state.website === undefined || state.website === null || state.website === ""){
        fmData.append("website","");  
      }else{
        fmData.append("website",state.website);  
      }

      //kisa aciklama
      if (state.kisaAciklama === undefined || state.kisaAciklama === null || state.kisaAciklama === "" ){
        warnmessage = "Lütfen kısa açıklama giriniz.";
        onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
        showWarningLayer();   
        customHideLoadingLayer();            
        return;
      }else{
        fmData.append("kisaAciklama",state.kisaAciklama);
      }

      //telefon
      if (state.telefon === undefined || state.telefon === null || state.telefon === "" ){
        warnmessage = "Lütfen telefon giriniz.";
        onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
        showWarningLayer();   
        customHideLoadingLayer();            
        return;
      }else{
        fmData.append("telefon",state.telefon);
      }
      
      fmData.append("emailaddress",state.eposta);
      fmData.append("status",state.status);
      fmData.append("userid",state.userid);
      fmData.append("documentid",state.documentid);
      fmData.append("documenttitle",state.documenttitle);
      fmData.append("fileid",state.fileid);
      fmData.append("password",xprops.login.User.Password);
      fmData.append("passwordx",xprops.login.User.Passwordx);
      fmData.append("sessionidx",xprops.login.User.SessionID);
      fmData.append("groupid",xprops.login.User.GroupID);
      fmData.append("viewtype",state.viewtype);
    }
    

    const config = {
      headers: { "content-type": "multipart/form-data" },
      crossDomain: true
    }

    const res = await axios.post(
      "https://www.sensizolmazsessizolmaz.org/html/do_updateprofile.php",
      //"http://localhost/soso/new-participant-form",
      fmData,
      config
    );
    if (res.data.type === "done"){
      warnmessage = "Profil güncellendi.";
      onWarning({warningMessageType:'success', warningMessageText: warnmessage});      
      showWarningLayer();
      setTimeout(()=>{
        //return window.location.reload();
      },2000);
    }else{
      if (res.data.message === "DP_CreateUser"){
        warnmessage = "Bu isim ile daha önceden kayıt oluşturulmuş. Lütfen tekrar deneyiniz.";
      }else if(res.data.message === "DP_InsertForm"){
        warnmessage = "Kayıt esnasında bir hata oluştu. Lütfen tekrar deneyiniz.";
      }else if(res.data.message === "DP_EmailAddress"){
        warnmessage = "Kayıt e-posta adresini kontrol ediniz.";
      }else{
        warnmessage = "Bir hata oluştu. Lütfen tekrar deneyiniz.";
      }
      onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
      showWarningLayer();
    }    
    customHideLoadingLayer();  

  }    

  const changePassword = async () => {
    customShowLoadingLayer();   
    const fmData = new FormData();
    let warnmessage = "";

    //step 1
    //FullName

    if (state.newpassword === ""){
      warnmessage = "Lütfen şifre alanlarını kontrol ediniz.";
      onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
      showWarningLayer();      
      customHideLoadingLayer();
      return;
    }

    if (state.newpasswordre === ""){
      warnmessage = "Lütfen şifre alanlarını kontrol ediniz.";
      onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
      showWarningLayer();      
      customHideLoadingLayer();
      return;
    }

    if (state.newpassword !== state.newpasswordre){
      warnmessage = "Şifre alanları aynı değil.";
      onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
      showWarningLayer();      
      customHideLoadingLayer();
      return;
    }    


    if (state.newpassword.length < 5){
      warnmessage = "Şifre en az 5 karakter olmalıdır.";
      onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
      showWarningLayer();      
      customHideLoadingLayer();
      return;      
    }


    fmData.append("p", state.newpassword);
    fmData.append("pre", state.newpasswordre);
    fmData.append("uid", xprops.login.User.UserID);    
    fmData.append("sid", xprops.login.User.SessionID);

    const config = {
      headers: { "content-type": "multipart/form-data" },
      crossDomain: true
    }

    const res = await axios.post(
      "https://www.sensizolmazsessizolmaz.org/html/do_changepassword.php",
      //"http://localhost/soso/new-participant-form",
      fmData,
      config
    );
    if (res.data.type === "done"){
      warnmessage = "Şifre güncellendi. Lütfen tekrar giriş yapınız.";
      onWarning({warningMessageType:'success', warningMessageText: warnmessage});      
      showWarningLayer();
      setTimeout(()=>{
        logOut();
        customHideLoadingLayer();  
        hideWarningLayer();
      },2000);
    }else{
      if (res.data.message === "DP_Empty"){
        warnmessage = "Lütfen şifre alanlarını kontrol ediniz.";
      }else if(res.data.message === "DP_InsertForm"){
        warnmessage = "Kayıt esnasında bir hata oluştu. Lütfen tekrar deneyiniz.";
      }else if(res.data.message === "DP_EmailAddress"){
        warnmessage = "Kayıt e-posta adresini kontrol ediniz.";
      }else{
        warnmessage = "Bir hata oluştu. Lütfen tekrar deneyiniz.";
      }
      onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
      showWarningLayer();
    }    
    customHideLoadingLayer();  




  }

  return(
    <React.Fragment>
      <div className="expertSectionWrapper expertProfile">
        <NavBarSub pageType="profile" returnPage={url}  />
        

        {
          state.isLoaded !== false ?
          <React.Fragment>
          <div className="expertWrapper">
            <div className="expertList">
              <div className="expertListSticky">
                <ImgCrop shape="round" modalTitle="Resim Düzenle" modalCancel="İptal" modalOk="Tamam" rotate grid>
                  <Upload
                    accept="image/*"
                    customRequest={uploadImage}
                    onChange={handleOnChange}
                    listType="picture-card"
                    defaultFileList={state.defaultFileList}
                    className="image-upload-grid"
                    onPreview={onPreview}
                    onRemove={removeImage}
                  >
                    {
                      state.defaultFileList.length >= 1 ? null 
                      :
                      <React.Fragment>
                        <div className="uploadContentWrappers">
                              <svg xmlns="http://www.w3.org/2000/svg" className="iconFemale" viewBox="0 0 50 50">
                                <g data-name="1" id="_1">
                                  <rect fill="none"/>
                                  <path d="M30.933,32.528  c-0.026-0.287-0.045-0.748-0.06-1.226c4.345-0.445,7.393-1.487,7.393-2.701c-0.012-0.002-0.011-0.05-0.011-0.07  c-3.248-2.927,2.816-23.728-8.473-23.306c-0.709-0.6-1.95-1.133-3.73-1.133c-15.291,1.157-8.53,20.8-12.014,24.508  c-0.002,0.001-0.005,0.001-0.007,0.001c0,0.002,0.001,0.004,0.001,0.006c0,0.001-0.001,0.002-0.001,0.002s0.001,0,0.002,0.001  c0.014,1.189,2.959,2.212,7.178,2.668c-0.012,0.29-0.037,0.649-0.092,1.25C19.367,37.238,7.546,35.916,7,45h38  C44.455,35.916,32.685,37.238,30.933,32.528z" fill="none" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2" />
                                </g>
                              </svg> 
                              <button className="buttonUpload">Resim Yükle</button> 

                        </div>
                      </React.Fragment>
                    }
                    
                  </Upload>
                  {state.progress > 0 ? <Progress percent={state.progress} /> : null}
                </ImgCrop>
              

                <div className="inputWrapper web buttonwrapper">
                  <button className="btnWeb btnFilterSearch save" onClick={saveProfile}>PROFİLİMİ GÜNCELLE</button>
                </div> 

              </div>
            </div>
            <div className="expertContent">
              <h2 className="webSectionTitle">Şifre Değiştir</h2>
              <div>
                <ul className="profileIntroUl">
                  <li className="profileItem">
                      <h4 className="sectionTitle">Yeni Şifre </h4>
                      <input type="password" placeholder="Lütfen yeni şifrenizi giriniz" name="newpassword" className="edits" onChange={handleChange} value={state.newpassword} />
                  </li>
                  <li className="profileItem">
                      <h4 className="sectionTitle">Yeni Şifre Tekrar </h4>
                      <input type="password" placeholder="Lütfen tekrar yeni şifrenizi giriniz" name="newpasswordre" className="edits" onChange={handleChange} value={state.newpasswordre} />
                  </li>
                  <li className="profileItem">
                    <button className="btnWeb btnFilterSearch resetHome" onClick={changePassword}>ŞİFRE GÜNCELLE</button>
                  </li>                
                </ul>
              </div>

              <h2 className="webSectionTitle">Profil Bilgileriniz</h2>
              <div>
              {
                state.viewtype !== null && state.viewtype !== undefined &&
                  state.viewtype === "kadinuzman" ?
                    <ul className="profileIntroUl">
                      <li className="profileItem">
                          <h4 className="sectionTitle">Adınız / Soyadınız </h4>
                          <input type="text" placeholder="Lütfen tam adınızı giriniz" name="fullname" className="edits" onChange={handleChange} value={state.fullname} />
                      </li>
                      <li className="profileItem">
                          <h4 className="sectionTitle">Uzmanlık Alanlarınız</h4>
                          <div className="contentInfoWrapper">
                          {
                            experties.map((item)=>{
                              return(
                              <CheckBox name={item} key={item} value={item} defaultChecked={state.checkboxes[item]} checked={state.checkboxes[item]} onChange={handleCheckboxChange} >{item}</CheckBox>
                              )
                            })
                          }
                        </div>                      
                      </li>
                      <li className="profileItem">
                        <h4 className="sectionTitle">Görüş Vermek İstediğiniz Konular</h4>
                        <div className="baglantiWrapper">
                          <input type="text" placeholder="Konu başlığı girin" className="edits" name="topicname" value={state.topicname} onChange={handleChange} />
                          <Button onClick={addToTopics}>Ekle</Button>
                        </div>
                        <div className="contentInfoWrapper gorus-konular gorus-konular-step2">
                          <ul className="ulYayinReferanslar">
                          {
                            state.gorusverilecekkonular.map((item,index)=>(
                              <li key={index} title={item}><button className="btn-remove-list" onClick={() => removeTopic(item,index)} ></button><span>{item}</span></li>
                            ))
                          }
                          </ul>
                        </div>
                      </li>
                      <li className="profileItem">
                          <h4 className="sectionTitle">Uzman Olduğunuz Ülkeler</h4>
                          <Selectx
                            multi
                            create
                            placeholder="Lütfen ülke seçiniz."
                            addPlaceholder="ülke seçiniz."
                            createNewLabel="Ekle"
                            options={countries}
                            values={state.uzmanolunanulkeler}
                            labelField="name"
                            valueField="name"
                            color="#ff004c"
                            onChange={(value) => 
                              setState({
                                ...state,
                                uzmanolunanulkeler: value
                              })

                            }
                          />
                      </li>
                      <li className="profileItem">
                        <h4 className="sectionTitle">Çalıştığınız Kurum </h4>
                        <input type="text" placeholder="Lütfen tam adınızı giriniz" name="calistigikurum" className="edits margin-bottom-10" onChange={handleChange} value={state.calistigikurum} />
                        <CheckBox value="bagimsiz" name="bagimsizmi" defaultChecked={state.bagimsizmi} checked={state.bagimsizmi}   onChange={(event) => handleBagimsizmi(event.target)}>Bağımsız Çalışıyorum</CheckBox>
                      </li>
                      <li className="profileItem">
                        <h4 className="sectionTitle">Websitesi </h4>
                        <input type="text" placeholder="Lütfen websitesi giriniz" name="websitesi" className="edits" onChange={handleChange} value={state.website} />
                      </li>
                      <li className="profileItem">
                        <h4 className="sectionTitle">Kısa Özgeçmiş </h4>
                        <textarea className="memos" placeholder={`Lütfen kısa özgeçmişinizi yazınız.`} name="kisaozgecmis" onChange={handleChange} value={state.kisaozgecmis} />
                      </li>
                      <li className="profileItem yasadigiulke">
                        <h4 className="sectionTitle">Yaşadığınız Ülke </h4>
                        <Selectx
                          values={state.yasadigiulke}
                          labelField="name"
                          valueField="name"
                          searchable={true}
                          addPlaceholder="yazarak filtreleyin"
                          placeholder="Lütfen ülke seçiniz."
                          options={countries}
                          multi={false}
                          className="select-country-city"
                          onChange={(value) => 
                            setState({
                              ...state,
                              yasadigi: value
                            })

                          }
                        />                    
                      </li>                  
                      <li className="profileItem">
                        <h4 className="sectionTitle">Yaşadığınız Şehir </h4>
                        <input type="text" placeholder="Lütfen şehir giriniz." name="yasadigisehir" className="edits" onChange={handleChange} value={state.yasadigisehir} />
                      </li>
                      <li className="profileItem">
                        <h4 className="sectionTitle">Medya deneyiminiz var mı?</h4>
                        <div className="medyaDeneyimiRadioWrapper">
                          <div name="medyaDeneyimi" className="medyaDeneyimiRadioGroup" onChange={handleMedyaVarmi}>
                            <RadioButton name="checkMedyaDeneyimi" defaultCheck={state.medyadeneyimivarmi === "1" ? true : false} value="1">Var</RadioButton>
                            <RadioButton name="checkMedyaDeneyimi" defaultCheck={state.medyadeneyimivarmi === "0" ? true : false} value="0">Yok</RadioButton>
                          </div>              
                        </div>                    
                      </li>
                      <li className="profileItem">
                        <h4 className="sectionTitle">Konferans deneyiminiz var mı?</h4>
                        <div className="medyaDeneyimiRadioWrapper">
                          <div name="konferansDeneyimi" className="medyaDeneyimiRadioGroup" onChange={handleKonferansVarmi}>
                            <RadioButton name="checkKonferansDeneyimi" defaultCheck={state.konferansdeneyimivarmi === "1" ? true : false} value="1">Var</RadioButton>
                            <RadioButton name="checkKonferansDeneyimi" defaultCheck={state.konferansdeneyimivarmi === "0" ? true : false} value="0">Yok</RadioButton>
                          </div>              
                        </div>                    
                      </li>
                      <li className="profileItem">
                        <h4 className="sectionTitle">İlgili yayın ve referans bağlantıları</h4>
                        <sub className="linkInfo">Buraya yayın, youtube bağlantısı ekleyebilirsiniz.</sub>
                        <div className="baglantiWrapper">
                          <input type="text" placeholder="Bağlantı adresi girin" className="edits" name="baglantiadi" value={state.baglantiadi} onChange={handleChange} />
                          <Button onClick={addToLinks}>Ekle</Button>
                        </div>
                        <div className="contentInfoWrapper gorus-konular gorus-konular-step2">
                          <ul className="ulYayinReferanslar">
                          {
                            state.yayinvereferanslar.map((item,index)=>(
                              <li key={index} title={item}><button className="btn-remove-list" onClick={() => removeLink(item,index)} ></button><span>{item}</span></li>
                            ))
                          }
                          </ul>
                        </div>
                      </li>
                      <li className="profileItem">
                        <h4 className="sectionTitle">Sosyal Medya Hesapları</h4>
                        <sub className="linkInfo">Sadece hesap adını yazınız. Örn. Twitter için sensizolmazorg</sub>

                        <div className="baglantiWrapper">
                          <Select 
                            className="socialSelect"
                            placeholder=""
                            defaultValue={{custom: true, typex:"facebook", link:"https://www.facebook.com/", label:"Facebook", value:"facebook"}}
                            formatOptionLabel={formatOptionLabel}
                            components={{ Option: CustomOption }}
                            options={options} 
                            isSearchable={false}
                            color="#ff004c"
                            onChange={handleSocialChange}
                          />              
                          <input type="text" placeholder="Hesap adı" className="edits" name="sociallink" value={state.sociallink} onChange={handleChange} />
                          <Button onClick={addToSocials}>Ekle</Button>
                        </div>  
                        <div className="contentInfoWrapper gorus-konular">
                          <ul className="ulYayinReferanslar socialMediaAccounts">
                          {
                            state.sosyalmedyahesaplari.map((item,index)=> {
                              let countOcc = countOccurences(item,"http"); 
                              return(
                                <li key={index} title={item}><button className="btn-remove-list" onClick={() => removeSocial(item,index)}><img className="btn-remove-list-icon" src="/assets/images/remove-list.png"/></button><div className="socialLine"><SocialIcon className="socialMediaIcon"  target="_blank" style={{ height: 30, width: 30 }} url={countOcc > 1 ? "Hatalı Link": item} /> <a href={item} target="_blank" rel="noreferrer" ><span className="socialMedyaInfo">{countOcc > 1 ? "Hatalı Link": item}</span></a></div> </li>
                                )
                            }
                            )
                          }
                          </ul>
                        </div>                                  
                      </li>
                      <li className="profileItem">
                        <div value="0" name="iletisimBilgileri" className="iletisimBilgileriCheckGroupPres">
                          <div className="formBox0">
                            <h4 className="sectionTitle">İletişim Bilgileriniz</h4>                  
                          </div>
                          <div className="formBox1">
                            <h5>Profilimde Görünsün</h5>
                          </div>
                          <div className="formBox2">
                            <h5>İletişim İçin Kullanılsın</h5>
                          </div>
                          <div className="formBox4">
                          <PhoneInput
                            //ref={(input) => { this.phoneInput = input; }}
                            placeholder="Telefon numaranızı giriniz"
                            value={state.profildetelefon}
                            onChange={handleProfildeTelefonChange}
                            name="profildetelefon"
                            className="edits"
                            defaultCountry="TR"
                          />                    
                            {
                              // <input type="text" ref={(input) => { this.phoneInput = input; }} placeholder="Telefon numaranızı giriniz" className="edits" value={state.phone} onChange={this.handlePhoneChange} />
                            }
                          </div>                  
                          <div className="formBox5">
                            <CheckBox value="" className="contactCheck" name="iletisimtercihtelefongoster" checked={ state.iletisimtercihtelefongoster } defaultCheck={state.iletisimtercihtelefongoster}  onChange={handleTableTicks}></CheckBox> 
                          </div>                  
                          <div className="formBox6">
                            <CheckBox value="" className="contactCheck" name="iletisimtercihtelefon" checked={state.iletisimtercihtelefon} defaultCheck={state.iletisimtercihtelefon} onChange={handleTableTicks}></CheckBox> 
                          </div>                  
                          <div className="formBox8">
                            <input type="text" name="profildeeposta" placeholder="E-Posta adresinizi giriniz" className="edits" value={state.profildeeposta} onChange={handleChange} />
                          </div>                  
                          <div className="formBox9">
                            <CheckBox value="" className="contactCheck" name="iletisimtercihepostagoster" checked={state.iletisimtercihepostagoster} defaultCheck={state.iletisimtercihepostagoster} onChange={handleTableTicks}></CheckBox> 
                          </div>                  
                          <div className="formBox10">
                            <CheckBox value="" className="contactCheck" name="iletisimterciheposta" checked={state.iletisimterciheposta} defaultCheck={false}  onChange={handleTableTicks}></CheckBox> 
                          </div>                                

                        </div>                    

                      </li>
                      <li className="profileItem">
                        <p className="infoAltyapi">
                          Size telefon numaranız ve e-posta adresiniz sitede gözükmeden ulaşılabilecek bir alt yapımız mevcut.Bunun için aşağıdaki tercihlerden birini seçiniz.
                          <span className="altyapiSub">
                            Size ulaşmak isteyen medya çalışanı telefon numaranızı görmeden sizi arayabilecek veya sms veya whatsapp gönderebilecek, e-mailler ise yine bizim platformumuz üzerinden sizin e-maillerinize düşecek. Doldurduğunuz formun iletişim bilgileri, sitede arama yapanlar tarafından görünmeyecek                    
                          </span>
                        </p>
                      
                      </li>
                      <li className="profileItem">
                        <div className="contactWrapper groupContacts">
                          <div value="0" name="iletisimBilgileri" className="iletisimBilgileriCheckGroup">
                            <CheckBox value="1" name="gizlitercihsms" checked={state.gizlitercihsms} defaultCheck={state.gizlitercihsms}  onChange={handleGizliTercihler} >Sms</CheckBox>
                            <CheckBox value="2" name="gizliterciharama" checked={state.gizliterciharama} defaultCheck={state.gizliterciharama }   onChange={handleGizliTercihler} >Arama</CheckBox>
                            <CheckBox value="3" name="gizlitercihwhatsapp" checked={state.gizlitercihwhatsapp} defaultCheck={state.gizlitercihwhatsapp}  onChange={handleGizliTercihler} >WhatsApp</CheckBox>
                          </div> 
                          <PhoneInput
                          //ref={(input) => { this.phoneInput = input; }}
                            placeholder="Telefon numaranızı giriniz"
                            value={state.gizlitelefon}
                            onChange={handleGizliTelefonChange}
                            disabled={ state.gizliplatformlar.length > 0 ? false : true }
                            className="edits"
                            defaultCountry="TR"
                          /> 
                        </div>                  
                      </li>
                      <li className="profileItem">
                          <div className="contactWrapper groupContacts2">
                            <CheckBox value="" className="contactCheck" checked={state.gizliemailcheck} onChange={handleGizliEmailCheck}  name="contactEmail" defaultCheck={state.gizliemailcheck}>E-posta :</CheckBox> <input type="text" placeholder="E-Posta adresinizi giriniz" className="edits" disabled={state.gizliemailcheck === true ? false : true} onChange={handleGizliEmailChange} value={state.gizlieposta} />
                          </div>              
                      </li>
                      <li className="profileItem">
                        <h4 className="sectionTitle">Durum</h4>
                        <div className="medyaDeneyimiRadioWrapper">
                          <div name="durum" className="medyaDeneyimiRadioGroup" onChange={handleStatus}>
                            <RadioButton name="checkStatus" defaultCheck={state.status === "1" ? true : false} value="1">Aktif</RadioButton>
                            <RadioButton name="checkStatus" defaultCheck={state.status === "0" ? true : false} value="0">Pasif</RadioButton>
                          </div>              
                        </div>                    
                      </li>

                    </ul>
                    :
                    <ul className="profileIntroUl">
                        <li className="profileItem">
                            <h4 className="sectionTitle">Adınız / Soyadınız </h4>
                            <input type="text" placeholder="Lütfen tam adınızı giriniz" name="fullname" className="edits" onChange={handleChange} value={state.fullname} />
                        </li>
                        <li className="profileItem">
                          <h4 className="sectionTitle">E-Posta Adresi </h4>
                          <input type="text" placeholder="Lütfen e-posta adresi giriniz" name="eposta" className="edits" onChange={handleChange} value={state.eposta} readOnly={true} />
                        </li>
                        <li className="profileItem">
                          <h4 className="sectionTitle">Telefon </h4>
                          <PhoneInput
                            placeholder="Telefon numaranızı giriniz"
                            value={state.telefon}
                            onChange={handleTelefonChange}
                            name="telefon"
                            className="edits"
                            defaultCountry="TR"
                          />                              
                        </li>
                        <li className="profileItem">
                          <h4 className="sectionTitle">Çalıştığınız Kurum </h4>
                          <input type="text" placeholder="Lütfen tam adınızı giriniz" name="calistigikurum" className="edits margin-bottom-10" onChange={handleChange} value={state.calistigikurum} />
                          <CheckBox value="bagimsiz" name="bagimsizmi" defaultChecked={state.bagimsizmi} checked={state.bagimsizmi}   onChange={(event) => handleBagimsizmi(event.target)}>Bağımsız Çalışıyorum</CheckBox>
                        </li>
                        <li className="profileItem">
                          <h4 className="sectionTitle">Websitesi </h4>
                          <input type="text" placeholder="Lütfen websitesi giriniz" name="websitesi" className="edits" onChange={handleChange} value={state.website} />
                        </li>
                        <li className="profileItem">
                          <h4 className="sectionTitle">Kısa Açıklama </h4>
                          <textarea className="memos" placeholder={`Lütfen kısa açıklama yazınız.`} name="kisaAciklama" onChange={handleChange} value={state.kisaAciklama} />
                        </li>
                        <li className="profileItem">
                          <h4 className="sectionTitle">Durum</h4>
                          <div className="medyaDeneyimiRadioWrapper">
                            <div name="durum" className="medyaDeneyimiRadioGroup" onChange={handleStatus}>
                              <RadioButton name="checkStatus" defaultCheck={state.status === "1" ? true : false} value="1">Aktif</RadioButton>
                              <RadioButton name="checkStatus" defaultCheck={state.status === "0" ? true : false} value="0">Pasif</RadioButton>
                            </div>              
                          </div>                    
                        </li>
                      </ul>
      
                 
              }
              </div>
                                         
              <div className="clean"></div>
            </div>
          </div>          
          </React.Fragment>
          :
          <React.Fragment>
            <div className="expertLoading">
              <p>Yükleniyor...</p>
            </div>
          </React.Fragment> 
        }

        </div>

     

    </React.Fragment>
  );
};

export default ProfilePage;