import React from 'react';
import './contactlist-item.styles.scss';

const ContactListItem = ({properties,isSelected,getExpertMessages,contactListData,getContactList,gid,selectedId}) => {
  const profilePic = "https://www.sensizolmazsessizolmaz.org/uploads/thumb_profile_"+properties.tid+".jpg?v="+Date.now();
  let tmpObj = {
    "fid":properties.fid,
    "tid":properties.tid,
    "img":properties.img,
    "fullname":properties.fullname,
    "sender":properties.sender,
    "receiver":properties.receiver,
    "createdate":properties.createdate,
    "selected":true
  }     
  return(
    <div className={`contactListItem ${selectedId === properties.fid ? 'selected' : selectedId === properties.tid ? 'selected' : '' }`} onClick={() => getContactList(properties.fid,properties.tid,tmpObj)}>
        <div className="contactListItemLeft">
          <img src={profilePic} alt={properties.fullname} title={properties.fullname} onError={(e)=>{e.target.onerror = null; e.target.src="/assets/images/default-woman.jpg"}}  />
        </div>
        <div className="contactListItemRight">
          <h6 className="contactListItemTitle">{properties.fullname}</h6>
          <span className="contactListItemTime">{properties.createdate}</span>
        </div>
    </div>
  );
}

export default ContactListItem;