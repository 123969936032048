import React from 'react';
import { Redirect } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ProjectActionTypes } from '../../redux/project/project.types';
import { DefinitionsActionTypes } from '../../redux/definitions/definitions.types';
import ImgCrop from 'antd-img-crop';
import { Upload, Progress } from 'antd';
import { showWarningLayer } from '../../utils/utils';

import axios from "axios";
import 'antd/dist/antd.css';
//import ImageUploader from 'react-images-upload';

//import uploadButton from '../../assets/img/upload.svg';
//import removeButton from '../../assets/img/delete.svg';

import './baslangic.page.styles.scss';

class Baslangic extends React.Component{

  constructor(props){
    super(props);
    this.state = {
    };
  }

  componentDidMount(){
    this.props.setCurrent('baslangic');
    
  }

  goTo = (event,type) => {
    event.preventDefault();
    this.props.setCurrent('step-1');
    return this.props.history.push('/signup/step-1');    
  }  


  render(){
    return(
      <section className="mainSection">
        <div className="sectionWrapper">
          <div className="baslangicWrapper">
            <p className="baslangicYazi">
              Sensiz Olmaz Sessiz Olmaz veri tabanına aşağıdaki adımları takip ederek kolayca kaydolabilirsiniz.
            </p>
            <p>
            <strong>Adım 1 :</strong> Adınız, soyadınız ve e-posta adresinizi kaydettikten sonra profilinizde görünecek bir fotoğraf eklemeniz gerekecek. Fotoğraf eklemek zorunlu değil, ancak eklemenizi öneririz. 
            </p>
            <p>
            <strong>Adım 2 : </strong> Aşağıya doğru açılan <strong>Uzmanlık Alanlarınız</strong> menüsünden kendi çalışma alanlarınızı belirleyebilirsiniz. <strong>Görüş Vermek İstediğiniz Konular</strong> kısmına çalışma alanı alt başlıklarını <u>mümkün olduğunca ayrıntılı bir şekilde</u> ekleyebilirsiniz. Lütfen çalışma alanlarınıza dair anahtar kelimeleri eklerken detay vermekten çekinmeyin. Burada gireceğiz anahtar kelimeler, veri tabanında aratıldığında size daha kolay ulaşılmasını sağlayacak.
            </p>
            <p>
            <strong>Adım 3 : </strong> Bu adımda, çalıştığınız kurumu, kurumun web sitesini ekleyerek belirtmeniz istenecek. Aynı zamanda "Bağımsız Çalışıyorum" seçeneği de mevcut. Sonrasında kısa bir özgeçmişinizi yazıp yaşadığınız ülke ve şehri seçebilirsiniz.
            </p> 
            <p>
            <strong>Adım 4 : </strong> Medya ve/veya konferans deneyimlerinizi profilinizde görünmesini istediğiniz ilgili video ve yayınları da ekleyerek son adıma geçebilirsiniz.
            </p>  
            <p>
            <strong>Adım 5 : </strong> Bu adımda, ilgili kurum çalışanlarının sizinle nasıl iletişime geçeceğini belirleyeceksiniz. Sosyal medya hesaplarınızı ekleyebilir; telefon ve e-posta aracılığıyla hangi yollarla ulaşılacağınızı kendiniz seçebilirsiniz.  
            </p>           
            <p>
            Son olarak, <strong>Aydınlatma Metni ve Ön Bilgilendirme Formu</strong>'nu okumanızı özellikle rica ederiz. Dikkatlice okuyup onayladıktan sonra "Tamamla" butonuna tıklayıp profilinizi oluşturabilirsiniz.
            </p>  
            <p>
            Profiliniz oluşturulduktan sonra, daha sonra düzenleme yapmanız için kullanıcı adı ve şifreniz belirttiğiniz e-posta adresine gönderilecek. 
            </p>             
            <p>Hoş geldiniz!</p>
              <button className="btnWeb btnFilterSearch btnBasla" onClick={(event) => this.goTo(event)}>BAŞLA</button>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    step1: state.definitions.step1,
    warningMessage: state.project.warningMessageText,
    warningType: state.project.warningMessageType,
    currentPage: state.project.currentPage
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onWarning: (payload) => dispatch({ type: ProjectActionTypes.SET_WARNING_MESSAGE, payload: payload}),
    setDefault: () => dispatch({ type: ProjectActionTypes.SET_DEFAULT}),
    setStep1: (step1) => dispatch({ type: DefinitionsActionTypes.SET_STEP1, step1: step1}),
    setCurrent: (value) => dispatch({type: ProjectActionTypes.SET_CURRENT, value: value })
  }
}




export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Baslangic));