import React, { useState, useEffect } from 'react';
//import SmoothScrolling from '../../../utils/smoothscrolling';
import { timer, scrollTo } from '../../../utils/utils';

const MenuItem = ({ itemName, active, itemCaption }) => {
  
  const [anchorTarget, setAnchorTarget] = useState(null);
  const [canclick, setCanclick ] = useState(true);

  useEffect(() => {
    setAnchorTarget(document.getElementById(itemName));
  },[itemName]);

  

  const handleClick = (event,a) => {
    event.preventDefault();
    setCanclick(false);
    // if (canclick !== false){

    // }
    
    //console.log(itemName);

    scrollTo(itemName,()=>{
      anchorTarget.scrollIntoView({ behavior: 'smooth', block: 'start'});
      setCanclick(true);
    });    
  }


  return(
    <li className={`${active === true ? "menuLiSelected" : ""}`}>
      <a href={`#${itemName}`}
          onClick={(event) => handleClick(event,itemName)}
          className={`${active === true ? "menuSelected" : ""}`}
          aria-label={`Scroll to ${itemName}`}>
        {itemCaption}
      </a>
    </li>
  );
};

export default MenuItem;