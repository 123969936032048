import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

//import { matchPath } from 'react-router';
import MediaQuery from 'react-responsive';
import {  Link, Switch, Route, useParams,useRouteMatch,useLocation } from 'react-router-dom';
import NavBarSub from '../../components/navbarsub/navbarsub.component';
import Article from '../../components/article/article.component';

import './articles.page.styles.scss';


const Articles = () => {
  
  let { path, url } = useRouteMatch();
  let { articleId } = useParams();
  let location = useLocation();
  const xprops = useSelector(state => state);
  const dispatch = useDispatch();  

  var lastCharUrl = url.substr(url.length - 1);
  if (lastCharUrl === "/"){
    url = url.slice(0, -1);
  }

  const handleScroll = () => {
    var element = document.getElementById("root");

    element.scrollIntoView();
  }

  const articlesList = {
    articles: xprops.project.latestPosts,
    slugs:{
      // 'sensiz-olmaz-sessiz-olmaz-hakkinda':1,
      'bu-haberde-kadinlar-nerede':2,
      "gazeteciligin-5n-1k-kurali-bir-5n-2k-olur-mu":1,
      "dayanisma-icinde-ve-yan-yana-durmak-iste-butun-mesele-bu":3,
      "femtech-kadin-sagligi-algisini-gelistirmek-icin-onemli-bir-firsat":4,
      "magara-kadinlari-nasil-yasadi":5,
      "sinemaci-kadinlar-bir-varolus-mucadelesi":6
    }
  };  



  return(
    <React.Fragment>
      <div className="articlePageSectionWrapper">
        <NavBarSub pageType="articles" articlesData={articlesList} location={location} url={url} menuOpen={false} />
        <div className="articlePageWrapper">
          <div className="articleList">
            <div className="articleListSticky">
              <h2 className="webSectionTitle">Blog</h2> 
              <MediaQuery minWidth={768}>                         
                <ul className="ulArticleList">
                  {
                    articlesList.articles.map((currentArticle,index) => {
                      let selected = "";
                      if (location.pathname === "/blog/" || location.pathname === "/blog"){
                        if (index === 0){
                          selected = "selected";
                        }
                      }else{
                        selected = location.pathname.includes(currentArticle.slug) ? 'selected' : '';
                      }
                      return(
                        <li key={currentArticle.id} className={selected}><Link to={`${url}/${currentArticle.slug}`}>{currentArticle.title}</Link></li>
                      )
                    })
                  }
                  
                </ul>
              </MediaQuery>
              <MediaQuery maxWidth={767}>
                  <p>Sol üst köşedeki menüden diğer makale başlıklarına ulaşabilirsiniz.</p>
              </MediaQuery>
            </div>
          </div>
          <div className="articlePageContent"  >
        
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Articles;