import React from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ProjectActionTypes } from '../../redux/project/project.types';
import { DefinitionsActionTypes } from '../../redux/definitions/definitions.types';
import { showWarningLayer } from '../../utils/utils';
import axios from 'axios';
import './footer.styles.scss';



class Footer extends React.Component{

  constructor(props){
    super(props);
    this.state = {
      buttonLabel: "İleri",
    };

    this.insertForm = this.insertForm.bind(this);
  }

  goTo = (event,type) => {
    event.preventDefault();
    let step = parseInt(this.props.currentPage.charAt(this.props.currentPage.length-1));
    //this.props.setLoading(true);

    //Step 1
    let warnmessage = "";



    if (type === "forward"){

      if (step === 1){


        // if (this.props.step1.profilePic === "" || this.props.step1.profilePic === undefined || this.props.step1.profilePic === null ){
        //   warnmessage = "Lütfen profil resmi yükleyiniz.";
        //   this.props.onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
        //   showWarningLayer();
        //   return;
        // }        





        if (this.props.step1.fullname === "" || this.props.step1.fullname === undefined || this.props.step1.fullname === null ){
          this.props.setStep1({
            ...this.props.step1,
            fullnameError: false
          });        
          warnmessage = "Lütfen Adınız / Soyadınız alanını kontrol edin.";
          this.props.onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
          showWarningLayer();
          return;
        }else{
          this.props.setStep1({
            ...this.props.step1,
            fullnameError: false
          });                
        }


        if (this.props.step1.emailaddress === "" || this.props.step1.emailaddress === undefined || this.props.step1.emailaddress === null ){
          warnmessage = "Lütfen e-posta alanını kontrol edin.";
          this.props.onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
          showWarningLayer();
          return;
        }else{
          var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
          if (!pattern.test(this.props.step1.emailaddress)) {
            warnmessage = "Lütfen geçerli bir e-posta adresi girin.";
            this.props.onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
            showWarningLayer();
            return;            
          }
        }        


      }else if(step === 2){

        if (this.props.step2.experties === null || this.props.step2.experties === undefined || this.props.step2.experties.length === 0 ){
          this.props.setStep2({
            ...this.props.step2,
            expertiesError: true,
            experties:[]
          });        
          warnmessage = "Lütfen uzmanlık alanlarınızı seçiniz.";
          this.props.onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
          showWarningLayer();
          return;
        }else{
          this.props.setStep2({
            ...this.props.step2,
            expertiesError: false,
          });                
        }     
        

        if (this.props.step2.topics === null || this.props.step2.topics === undefined || this.props.step2.topics.length === 0 ){
          this.props.setStep2({
            ...this.props.step2,
            topicsError: true,
            topics:[]
          });        
          warnmessage = "Lütfen görüş vermek istediğiniz konuları seçiniz.";
          this.props.onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
          showWarningLayer();
          return;
        }else{
          this.props.setStep2({
            ...this.props.step2,
            topicsError: false
          });    
          
          
          
        }     
        
        if (this.props.step2.countries === null || this.props.step2.countries === undefined || this.props.step2.countries.length === 0 ){
          this.props.setStep2({
            ...this.props.step2,
            countriesError: true,
            countries:[]
          });        
          warnmessage = "Lütfen uzman olduğunuz ülkeleri seçiniz.";
          this.props.onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
          showWarningLayer();
          return;
        }else{
          this.props.setStep2({
            ...this.props.step2,
            countriesError: false
          });                
        }     
        
        

        // if (this.props.step2.regions === null || this.props.step2.regions === undefined || this.props.step2.regions.length === 0 ){
        //   this.props.setStep2({
        //     ...this.props.step2,
        //     regionsError: true,
        //     regions:[]
        //   });        
        //   warnmessage = "Lütfen uzman olduğunuz bölgeleri seçiniz.";
        //   this.props.onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
        //   showWarningLayer();
        //   return;
        // }else{
        //   this.props.setStep2({
        //     ...this.props.step2,
        //     regionsError: false
        //   });                
        // }   

      }else if (step === 3){

        if (this.props.step3.isFree === false || this.props.step3.isFree === null || this.props.step3.isFree === undefined ){
          // if (this.props.step3.company === ""){
          //   warnmessage = "Lütfen çalıştığınız kurumu giriniz.";
          //   this.props.onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
          //   showWarningLayer();
          //   return;
          // }
        }

        if (this.props.step3.shortcv === ""){
          warnmessage = "Lütfen kısa özgeçmiş giriniz.";
          this.props.onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
          showWarningLayer();
          return;
        }

        if (this.props.step3.country === ""){
          warnmessage = "Lütfen yaşadığınız ülkeyi seçiniz.";
          this.props.onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
          showWarningLayer();
          return;
        }

        if (this.props.step3.city === ""){
          warnmessage = "Lütfen yaşadığınız şehiri giriniz.";
          this.props.onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
          showWarningLayer();
          return;
        }


      }      
      step = step+1;
      if (step >= 5){
        if (step > 5){
          this.insertForm();          
        }else{
          this.props.setCurrent('step-5');
          return this.props.history.push('/signup/step-5');    
  
        }
      } else{
        this.props.setCurrent('step-'+step);
        return this.props.history.push('/signup/step-'+step);    
      }   
    }else if (type === "baslangic"){
      this.props.setCurrent('baslangic');
      return this.props.history.push('/signup/baslangic');    

    }else{
      step = step-1;
      if (step === 0){
        this.props.setCurrent('step-1');
        return this.props.history.push('/signup/step-1');    
      }else{
        this.props.setCurrent('step-'+step);
        return this.props.history.push('/signup/step-'+step);    
      }
    }
  }  

  insertForm = async () => {
    this.props.setLoading(true);
    const fmData = new FormData();
    let warnmessage = "";

    //step 1
    //profile picture
    if (this.props.step1.profilePic === "" || this.props.step1.profilePic === undefined || this.props.step1.profilePic === null ){
      // warnmessage = "Lütfen profil resmi yükleyiniz.";
      // this.props.onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
      // showWarningLayer();
      // return;
      fmData.append("profilepic","");
      fmData.append("ext","");
    }else{
      fmData.append("profilepic",this.props.step1.profilePic);
      fmData.append("ext",this.props.step1.ext);
    }        

    //FullName
    if (this.props.step1.fullname === "" || this.props.step1.fullname === undefined || this.props.step1.fullname === null ){
      warnmessage = "Lütfen ad / soyad alanını kontrol edin.";
      this.props.onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
      showWarningLayer();      
      this.props.setLoading(false);
      this.props.setCurrent('step-1');
      return this.props.history.push('/signup/step-1');    
    }else{
      fmData.append("fullname", this.props.step1.fullname);
    }

    if (this.props.step1.emailaddress === "" || this.props.step1.emailaddress === undefined || this.props.step1.emailaddress === null ){
      warnmessage = "Lütfen ad / soyad alanını kontrol edin.";
      this.props.onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
      showWarningLayer();      
      this.props.setLoading(false);
      this.props.setCurrent('step-1');
      return this.props.history.push('/signup/step-1');    
    }else{
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(this.props.step1.emailaddress)) {
        warnmessage = "Lütfen geçerli bir e-posta adresi girin.";
        this.props.onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
        showWarningLayer();
        return;            
      }else{
        fmData.append("emailaddress", this.props.step1.emailaddress);
      }
    }

    //step 2

    //Experties
    if (this.props.step2.experties === undefined || this.props.step2.experties === null || this.props.step2.experties.length === 0 ){
      warnmessage = "Lütfen uzmanlık alanlarınızı seçin.";
      this.props.onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
      showWarningLayer();      
      this.props.setLoading(false);
      this.props.setCurrent('step-2');
      return this.props.history.push('/signup/step-2');          
    }else{
      fmData.append("experties",JSON.stringify(this.props.step2.experties));
    }

    //Topics
    if (this.props.step2.topics === undefined || this.props.step2.topics === null || this.props.step2.topics.length === 0 ){
      warnmessage = "Lütfen görüş vermek istediğiniz konu girin.";
      this.props.onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
      showWarningLayer();      
      this.props.setLoading(false);
      this.props.setCurrent('step-2');
      return this.props.history.push('/signup/step-2');          
    }else{
      fmData.append("topics",JSON.stringify(this.props.step2.topics));
    }

    //Countries
    if (this.props.step2.countries === undefined || this.props.step2.countries === null || this.props.step2.countries.length === 0 ){
      warnmessage = "Lütfen uzman olduğunuz ülkeleri seçin.";
      this.props.onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
      showWarningLayer();   
      this.props.setLoading(false);   
      this.props.setCurrent('step-2');
      return this.props.history.push('/signup/step-2');          
    }else{
      fmData.append("countries",JSON.stringify(this.props.step2.countries));
    }

    //Regions
    // if (this.props.step2.regions === undefined || this.props.step2.regions === null || this.props.step2.regions.length === 0 ){
    //   warnmessage = "Lütfen uzman olduğunuz bölgeleri seçin.";
    //   this.props.onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
    //   showWarningLayer();      
    //   this.props.setLoading(false);
    //   this.props.setCurrent('step-2');
    //   return this.props.history.push('/signup/step-2');          
    // }else{
    //   fmData.append("regions",JSON.stringify(this.props.step2.regions));
    // }

    //step 3

    //Check for freelance & company
    if (this.props.step3.isFree === undefined || this.props.step3.isFree === null || this.props.step3.isFree === false ){
      if (this.props.step3.company === undefined || this.props.step3.company === null || this.props.step3.company === ""){
        // warnmessage = "Lütfen çalıştığınız kurum adını giriniz.";
        // this.props.onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
        // showWarningLayer();   
        // this.props.setLoading(false);   
        // this.props.setCurrent('step-3');
        // return this.props.history.push('/signup/step-3');          
        fmData.append("company","");  
      }else{
        fmData.append("isfree","0");  
        fmData.append("company",this.props.step3.company);  
      }
    }else{
      fmData.append("isfree","1");  
      fmData.append("company","");  
    }

    //Website
    if (this.props.step3.website === undefined || this.props.step3.website === null || this.props.step3.website === ""){
      fmData.append("website","");  
    }else{
      fmData.append("website",this.props.step3.website);  
    }

    //short cv
    if (this.props.step3.shortcv === undefined || this.props.step3.shortcv === null || this.props.step3.shortcv === "" ){
      warnmessage = "Lütfen kısa özgeçmiş giriniz.";
      this.props.onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
      showWarningLayer();   
      this.props.setLoading(false);   
      this.props.setCurrent('step-3');
      return this.props.history.push('/signup/step-3');          
    }else{
      fmData.append("shortcv",this.props.step3.shortcv);
    }

    //country 
    if (this.props.step3.country === undefined || this.props.step3.country === null || this.props.step3.country === "" ){
      warnmessage = "Lütfen yaşadığınız ülkeyi seçiniz.";
      this.props.onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
      showWarningLayer();  
      this.props.setLoading(false);    
      this.props.setCurrent('step-3');
      return this.props.history.push('/signup/step-3');          
    }else{
      fmData.append("country",this.props.step3.country[0]["name"]);
    }    

    //city 
    if (this.props.step3.city === undefined || this.props.step3.city === null || this.props.step3.city === "" ){
      warnmessage = "Lütfen yaşadığınız şehiri giriniz.";
      this.props.onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
      showWarningLayer();     
      this.props.setLoading(false); 
      this.props.setCurrent('step-3');
      return this.props.history.push('/signup/step-3');          
    }else{
      fmData.append("city",this.props.step3.city);
    }    

    //step 4
    //konferans varmi
    if (this.props.step4.konferansvarmi === "" || this.props.step4.konferansvarmi === undefined || this.props.step4.konferansvarmi === null ){
      fmData.append("konferansvarmi", "0");
    }else{
      fmData.append("konferansvarmi",this.props.step4.konferansvarmi);
    }    

    //medya varmi
    if (this.props.step4.medyavarmi === "" || this.props.step4.medyavarmi === undefined || this.props.step4.medyavarmi === null ){
      fmData.append("medyavarmi", "0");
    }else{
      fmData.append("medyavarmi",this.props.step4.medyavarmi);
    }    

    //links
    if (this.props.step4.links === undefined || this.props.step4.links === null || this.props.step4.links.length === 0 ){
      fmData.append("links", "");
    }else{
      fmData.append("links",JSON.stringify(this.props.step4.links));
    }    

    //step 5

    //socials
    if (this.props.step5.socials === undefined || this.props.step5.socials === null || this.props.step5.socials.length === 0 ){
      fmData.append("socials", "");
    }else{
      fmData.append("socials",JSON.stringify(this.props.step5.socials));
    }        

    fmData.append("showInfo","0");
    fmData.append("phone",this.props.step5.phone);
    fmData.append("email",this.props.step5.email);
    if (this.props.step5.tercihphoneshow !== null && this.props.step5.tercihphoneshow !== undefined && this.props.step5.tercihphoneshow === true ){
      fmData.append("tercihphoneshow","1");
    }else{
      fmData.append("tercihphoneshow","0");
    }
    if (this.props.step5.tercihphoneuse !== null && this.props.step5.tercihphoneuse !== undefined && this.props.step5.tercihphoneuse === true ){
      fmData.append("tercihphoneuse","1");
    }else{
      fmData.append("tercihphoneuse","0");
    }        
    if (this.props.step5.tercihemailshow !== null && this.props.step5.tercihemailshow !== undefined && this.props.step5.tercihemailshow === true ){
      fmData.append("tercihemailshow","1");
    }else{
      fmData.append("tercihemailshow","0");
    }
    if (this.props.step5.tercihemailuse !== null && this.props.step5.tercihemailuse !== undefined && this.props.step5.tercihemailuse === true ){
      fmData.append("tercihemailuse","1");
    }else{
      fmData.append("tercihemailuse","0");
    }   

    if (this.props.step5.gizliTercihler !== undefined && this.props.step5.gizliTercihler !== null && this.props.step5.gizliTercihler.length !== 0 ){
      fmData.append("gizlitercihler",JSON.stringify(this.props.step5.gizliTercihler));
      //gizli gizliemailcheck
      if (this.props.step5.gizliphone === undefined || this.props.step5.gizliphone === null || this.props.step5.gizliphone === "" ){
        warnmessage = "Lütfen telefon numarası giriniz.";
        this.props.onWarning({warningMessageType:'fail', warningMessageText: warnmessage});
        showWarningLayer();      
        this.props.setLoading(false);
        this.props.setCurrent('step-5');
        return this.props.history.push('/signup/step-5');          
      }else{
        fmData.append("gizliphone",this.props.step5.gizliphone);
      }        
    }

    if (this.props.step5.gizliemailcheck !== undefined && this.props.step5.gizliemailcheck !== null && this.props.step5.gizliemailcheck === true ){
      //gizli email
      if (this.props.step5.gizliemail === undefined || this.props.step5.gizliemail === null || this.props.step5.gizliemail === "" ){
        warnmessage = "Lütfen e-posta adresi giriniz.";
        this.props.onWarning({warningMessageType:'fail', warningMessageText: warnmessage});
        showWarningLayer();      
        this.props.setLoading(false);
        this.props.setCurrent('step-5');
        return this.props.history.push('/signup/step-5');          
      }else{
        fmData.append("gizliemail",this.props.step5.gizliemail);
      }        
    }     




    //Check if user form approved
    if (this.props.step5.rizaMetni === undefined || this.props.step5.rizaMetni === null || this.props.step5.rizaMetni === false){
      warnmessage = "Lütfen ön bilgilendirme formunu onaylayın.";
      this.props.onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
      showWarningLayer();      
      this.props.setLoading(false);
      this.props.setCurrent('step-5');
      return this.props.history.push('/signup/step-5');                
    }

    const config = {
      headers: { "content-type": "multipart/form-data" },
      crossDomain: true
    }

    const res = await axios.post(
      "https://www.sensizolmazsessizolmaz.org/html/do_addparticipantform.php",
      //"http://localhost/soso/new-participant-form",
      fmData,
      config
    );
    if (res.data.type === "done"){
      warnmessage = "Katılımcı Bilgi Formu başarıyla gönderildi.";
      this.props.onWarning({warningMessageType:'success', warningMessageText: warnmessage});      
      showWarningLayer();
      this.props.setLoading(false);
      setTimeout(()=>{
        this.props.setCurrent('success'); 
        return this.props.history.push('/signup/success');         
      },2000);
    }else{
      if (res.data.message === "DP_CreateUser"){
        warnmessage = "Bu isim ile daha önceden kayıt oluşturulmuş. Lütfen tekrar deneyiniz.";
      }else if(res.data.message === "DP_InsertForm"){
        warnmessage = "Kayıt esnasında bir hata oluştu. Lütfen tekrar deneyiniz.";
      }else if(res.data.message === "DP_EmailAddress"){
        warnmessage = "Kayıt e-posta adresini kontrol ediniz.";
      }else{
        warnmessage = "Bir hata oluştu. Lütfen tekrar deneyiniz.";
      }
      this.props.onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
      showWarningLayer();
    }    
    this.props.setLoading(false);

  }  

  componentDidMount(){
    let step = parseInt(this.props.currentPage.charAt(this.props.currentPage.length-1));
    this.props.setLoading(false);    
    if (step === 5){
      this.setState({...this.state,buttonLabel: 'Tamamla'});
    }else{
      this.setState({...this.state,buttonLabel: 'İleri'});
    }

  }

  render(){
    return(
      <footer>
        {

          
            this.props.currentPage === "baslangic" ? <span></span>
            : 
            <React.Fragment>
            {
              this.props.currentPage === "step-1" ? 
                <Link className="backButtonLinkWrapper" onClick={(event) => this.goTo(event,'baslangic')}>
                  <svg className="backButton" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><title/><g data-name="1" id="_1"><path d="M353,450a15,15,0,0,1-10.61-4.39L157.5,260.71a15,15,0,0,1,0-21.21L342.39,54.6a15,15,0,1,1,21.22,21.21L189.32,250.1,363.61,424.39A15,15,0,0,1,353,450Z"/></g></svg>
                  <h3 className="backButtonText">Başlangıç</h3>  
              </Link>
              
                : 
                <Link className="backButtonLinkWrapper" onClick={(event) => this.goTo(event,'backward')}>
                {
                  this.props.currentPage !== "step-1" && 
                    <svg className="backButton" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><title/><g data-name="1" id="_1"><path d="M353,450a15,15,0,0,1-10.61-4.39L157.5,260.71a15,15,0,0,1,0-21.21L342.39,54.6a15,15,0,1,1,21.22,21.21L189.32,250.1,363.61,424.39A15,15,0,0,1,353,450Z"/></g></svg>  
                }
                {
                  this.props.currentPage !== "step-1" && 
                  <h3 className="backButtonText">Geri</h3>
                }
              </Link>

            }

            <Link className="nextButtonLinkWrapper" onClick={(event) => this.goTo(event,'forward')}>
              <h3 className={`nextButtonText ${this.props.currentPage === "step-5" ? "nextButtonTextDone" : ""}`}>{this.props.currentPage === "step-5" ? "Tamamla" : "İleri"}</h3>
              {
                this.props.currentPage === "step-5" ?
                  <svg className="nextButtonDone" viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg"><title/><path d="M64,0a64,64,0,1,0,64,64A64.07,64.07,0,0,0,64,0Zm0,122a58,58,0,1,1,58-58A58.07,58.07,0,0,1,64,122Z"/><path d="M87.9,42.36,50.42,79.22,40.17,68.43a3,3,0,0,0-4.35,4.13l12.35,13a3,3,0,0,0,2.12.93h.05a3,3,0,0,0,2.1-.86l39.65-39a3,3,0,1,0-4.21-4.28Z"/></svg>
                  :
                  <svg className="nextButton" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><title/><g data-name="1" id="_1"><path d="M202.1,450a15,15,0,0,1-10.6-25.61L365.79,250.1,191.5,75.81A15,15,0,0,1,212.71,54.6l184.9,184.9a15,15,0,0,1,0,21.21l-184.9,184.9A15,15,0,0,1,202.1,450Z"/></g></svg>
              }
              
            </Link>          
          </React.Fragment>

        }


      </footer>      
    );
  }
}

const mapStateToProps = state => {
  return {
    step1: state.definitions.step1,
    step2: state.definitions.step2,
    step3: state.definitions.step3,
    step4: state.definitions.step4,
    step5: state.definitions.step5,
    warningMessage: state.project.warningMessageText,
    warningType: state.project.warningMessageType,
    currentPage: state.project.currentPage,
    showLoading: state.project.showLoading
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onWarning: (payload) => dispatch({ type: ProjectActionTypes.SET_WARNING_MESSAGE, payload: payload}),
    setCurrent: (value) => dispatch({type: ProjectActionTypes.SET_CURRENT, value: value }),
    setStep1: (step1) => dispatch({ type: DefinitionsActionTypes.SET_STEP1, step1: step1}),
    setStep2: (step2) => dispatch({ type: DefinitionsActionTypes.SET_STEP2, step2: step2}),
    setStep3: (step3) => dispatch({ type: DefinitionsActionTypes.SET_STEP3, step3: step3}),
    setStep4: (step4) => dispatch({ type: DefinitionsActionTypes.SET_STEP4, step4: step4}),
    setStep5: (step5) => dispatch({ type: DefinitionsActionTypes.SET_STEP5, step5: step5}),
    setLoading: (showLoading) => dispatch({ type: ProjectActionTypes.SET_LOADING, showLoading: showLoading}),    
  }
}


export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Footer));