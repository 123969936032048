import React, { useState, useEffect } from 'react';
import { Switch, Route, Redirect,useRouteMatch} from 'react-router-dom';
import { ProjectActionTypes } from '../../redux/project/project.types';

import HeaderMedya from '../../components/headermedya/headermedya.component';
import KayitFormu from '../../pages/kayitformu/kayitformu.component';
import BaslangicMedya from '../baslangicmedya/baslangicmedya.page.component';
import MedyaSuccess from '../successmedya/successmedya.component';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import * as actionCreators from '../../redux/login/login.actions';

const MedyaKayit = () => {
  const [mounted, setMounted] = useState(false);
  const xprops = useSelector(state => state);
  const dispatch = useDispatch();
  const setParent = (value) => dispatch({type: ProjectActionTypes.SET_PARENT, value: value });  
  const closePopup = () => dispatch(actionCreators.setFormLoginStatus(false));
  let match = useRouteMatch();
  if(!mounted){
    setParent("medyakayit");  
  }

  useEffect(() =>{
    setMounted(true)
    closePopup();
  },[]);  

  //const setCurrent = (value) => dispatch({type: ProjectActionTypes.SET_CURRENT, value: value });  
  
  
    
  
  return(
    <React.Fragment>
    {
        <HeaderMedya currentPage={xprops.project.currentPage} />
    }
      
      <Switch>
        <Route exact path={`${match.path}/baslangicmedya`} component={BaslangicMedya} />
        <Route path={`${match.path}/kayit-formu`} component={KayitFormu} />
        <Route path={`${match.path}/medyasuccess`} component={MedyaSuccess} />
        <Route exact path="/medyakayit">
          <Redirect to={`${match.path}/baslangicmedya`} />
        </Route>        
      </Switch> 
    </React.Fragment>

  );
}


export default MedyaKayit;