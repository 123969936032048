import React,{ useState } from 'react';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { ProjectActionTypes } from '../../../redux/project/project.types';
import Select from "react-dropdown-select";
import { scrollTo,customShowLoadingLayer,customHideLoadingLayer,showWarningLayer, hideWarningLayer } from '../../../utils/utils';
import {expertiesForSelect} from '../../../utils/experties';



import './filter.styles.scss';
import { extendWith } from 'lodash';

const Filter = () => {
  const xprops = useSelector(state => state);
  const dispatch = useDispatch();
  const history = useHistory();
  const[keyword,setKeyword] = useState("");
  const[expert,setExpert] = useState("");

  const onWarning = (payload) => {
    dispatch({ type: ProjectActionTypes.SET_WARNING_MESSAGE, payload: payload});
  }

  const setSearchQueryParams = (payload) => {
    dispatch({ type: ProjectActionTypes.SET_SEARCH_QUERY_PARAMS, payload: payload});
  }


  const handleChangeExpert = (e) => {
    setExpert(e[0].value);
  }

  const handleChangeKeyword = (event) => {
    setKeyword(event.target.value);
  }

  const filterSearch = () => {

    customShowLoadingLayer();
    let warnmessage = "";
    if ((expert === null || expert === "") && (keyword === null || keyword === "")){
      warnmessage = "Arama kritirleri beraber boş olamaz."
      onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
      showWarningLayer(); 
      customHideLoadingLayer();
      return;
    }

    //const profession = expert === null ? "" : expert;
    let url = "/arama-sonuclari?page=1";

    if (keyword !== null && keyword !== ""){
      url = url + "&keyword="+keyword;
      if (expert !== null && expert !== ""){
        url = url + "&profession="+expert;
      }
    }else{
      if (expert !== null && expert !== ""){
        url = url + "&profession="+expert;
      }      
    }
    setSearchQueryParams(
      {
        page:1,
        keyword: keyword !== null ? keyword : "",
        profession: expert !== null ? expert : ""
      }
    );      
    history.push(url);
    // setTimeout(()=> {
    //   //history.push("/arama-sonuclari");
    //   //customHideLoadingLayer();
    // },2000)

  }

  const handleGo = () => {
    history.push("/signup");
  }

  return(
    <React.Fragment>
      <div id="filterID" className="filterSectionWrapperID">
        <div className="filterSectionWrapper">
          <div className="filterGridWrapper">
            <div className="filterWrapper">
              <div className="filterBox1">
                <div className="womanExpertJoinButtonWrapper">
                  <button onClick={handleGo}>
                    <h3>KADIN UZMANLAR ARASINA KATILIN&nbsp;</h3>
                  </button>
                </div>
                <div className="womanExpertSearchButtonWrapper">
                  <button onClick={()=> scrollTo('navID')}>
                    <h3>ALANINDA UZMAN KADINLARI BULUN&nbsp;</h3>
                  </button>
                </div>
              </div>
              {
              // <div className="filterBox2">
              //   <div className="womanExpertSearchButtonWrapper">
              //     <button onClick={()=> scrollTo('navID')}>
              //       <h3>Alanında uzman kadınları bulun</h3>
              //     </button>
              //   </div>
              // </div>
              }
              <div className="filterBox3">
                  <div className="inputWrapper web">
                    <div className="inputSubWrapper for">
                      <input type="text" placeholder="Anahtar kelime" className="editfilter withicon" value={keyword} onChange={(event)=>handleChangeKeyword(event)}  />
                    </div>
                  </div>
                  <div className="inputWrapper web second">
                    <div className="inputSubWrapper forDropdown">
                      <Select
                        multi={false}
                        placeholder="Lütfen uzmanlık alanı seçiniz."
                        options={expertiesForSelect}
                        value={expert}
                        color="#9b897c"
                        searchable={false}
                        onChange={handleChangeExpert}
                      />
                    </div>
                  </div> 
              </div>
              <div className="filterBox4">
                <div className="inputWrapper web buttonwrapper">
                  <button className="btnWeb btnFilterSearch" onClick={filterSearch}>UZMAN BUL</button>
                </div>               
              </div>
            {
              // <div className="filterBox5">
              //   <div className="inputWrapper web">
              //     <Select
              //       placeholder="Lütfen konu başlığı seçiniz."
              //       addPlaceholder="Uzmanlık Alanı"
              //       createNewLabel="Ekle"
              //       values="Uzmanlık Alanı"
              //       labelField="name"
              //       valueField="name"
              //       color="#9b897c"
              //     />          
              //   </div>          
              // </div>
              // <div className="filterBox6">
              //   <div className="inputWrapper web buttonwrapper">
              //     <button className="btnWeb btnFilterSearch">UZMAN BUL</button>
              //   </div>
              // </div>
            }
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Filter;