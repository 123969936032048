import React from 'react';
import './messagelist-item.styles.scss';

const MessageListItem = ({loginUserId,toId,fromId,message,sender,receiver,sendtime}) => {

  const profilePic = "https://www.sensizolmazsessizolmaz.org/uploads/thumb_profile_"+fromId+".jpg?v="+Date.now();
  
  return(
    <div className={`messageItem ${loginUserId === toId ? "messageLeft" : "messageRight"}`}  >
        {
          loginUserId !== fromId &&
          <div className="messageImageLayer">
            <img src={profilePic} alt={sender} onError={(e)=>{e.target.onerror = null; e.target.src="/assets/images/default-woman.jpg"}}  />
          </div>

        }
        <div className="messageTextLayer">
          <p className={`messageText ${loginUserId === toId ? "textLeft" : "textRight"}`}>{message}</p>
          <span className={`messageTime ${loginUserId === toId ? "textLeft" : "textRight"}`}>{sendtime}</span>
        </div>
    </div>
  );
}

export default MessageListItem;