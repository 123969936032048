import React from 'react';
import { Link } from 'react-router-dom';
import './contact.styles.scss';

const Contact = () => {
  return(
    <React.Fragment>
      <div id="iletisimID" className="contactSectionWrapper">
        <h2 className="webSectionTitle">İLETİŞİM</h2>
         <p className="nomargin">
          Soru ve mesajlarınızı e-posta yoluyla bize iletebilirsiniz.
         </p> 
         <div className="contactEmailWrapper nomargin">
          <span className="webEmailAddress">info@sensizolmazsessizolmaz.org</span>
         </div>          
         <div className="contactEmailWrapper">
            <img className="soso-bottom-logo" src={process.env.PUBLIC_URL + '/assets/images/soso-bottom-logo.png'} alt="Sensiz Olmaz Sessiz Olmaz" />
         </div>          

         <div className="contactEmailWrapper followUsContent">
          <div className="followUsIcons">
            <a target="_blank"  rel="noopener noreferrer" href="https://www.instagram.com/sensizolmazorg/"><i className="fab fa-instagram"></i></a>
            <a target="_blank"  rel="noopener noreferrer" href="https://www.youtube.com/channel/UCc1IMJQYrM7FfYs2zFgTjOA"><i className="fab fa-youtube"></i></a>
            <a target="_blank"  rel="noopener noreferrer" href="https://twitter.com/sensizolmazorg"><i className="fab fa-twitter"></i></a>
            <a target="_blank"  rel="noopener noreferrer" href="https://www.linkedin.com/company/sensizolmazorg"><i className="fab fa-linkedin"></i></a>
          </div>
         </div>                   
        <div className="bottomInfoLayer">
          <p className="bottomspace">Soru ve mesajlarınızı e-posta yoluyla bize iletebilirsiniz.</p>
          <p>Email Adresi</p>
          <p>info@sensizolmazsessizolmaz.org</p>
          <p><Link to="/sss"><span className="sssLinkTitle">Sık Sorulan Sorular </span></Link></p>

        
        </div>
      </div>
    </React.Fragment>
  );
}

export default Contact;