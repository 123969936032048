import { result } from 'lodash-es';
import React, { useState, useEffect } from 'react';
import {isSafari} from 'react-device-detect';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { ProjectActionTypes } from '../../../redux/project/project.types';

import MenuItem from '../menuitem/menuitem.component';
import './menu.styles.scss';

const menuItems = {
  filterID: 0,
  hakkindaID: null,
  makalelerID: null,
  egitimlerID: null,
  bizkimizID: null,
  iletisimID: null
}

const menuItemsCaption = {
  filterID: "UZMAN BUL",
  hakkindaID: "HAKKINDA",
  makalelerID: "BLOG",
  egitimlerID: "EĞİTİMLER",
  bizkimizID: "BİZ KİMİZ",
  iletisimID: "İLETİŞİM"
}

const Menu = () => {

  const [activeItem, setActiveItem] = useState(null); // locationdan gelen hashtag e gore set et
  const xprops = useSelector(state => state);
  const dispatch = useDispatch();
  const setScroll = (value) => dispatch({type: ProjectActionTypes.SET_SCROLL, value: value });  


  useEffect(() => {
    const observer = new MutationObserver(getAnchorPoints);
    observer.observe(document.getElementById('root'),{
      childList:true,
      subtree: true
    });
    getAnchorPoints();
    window.addEventListener('scroll',handleScroll);
    window.addEventListener('resize',getAnchorPoints);

    return () => {
      observer.disconnect();
      window.removeEventListener('scroll',handleScroll);
      window.removeEventListener('resize',getAnchorPoints);
    };
  }, [])


  const getAnchorPoints = () => {
    let curScroll = window.scrollY;
    for(const key in menuItems){
      if (curScroll < 0){
        curScroll = 0;
      }
      try{

        if (isSafari){
          menuItems[key] = document.getElementById(key).getBoundingClientRect().top + curScroll + 50;
        }else{
          menuItems[key] = document.getElementById(key).getBoundingClientRect().top + curScroll;
        }
        //menuItems[key] = document.getElementById(key).getBoundingClientRect().top + curScroll;

        if (isSafari){
          if (key === "iletisimID"){
            menuItems[key] = parseInt(menuItems[key]) - 330;
          }else{
            menuItems[key] = parseInt(menuItems[key]) - 50;
          }
          
        }
        //handleScroll();
      }catch(ex){
      }
      
    }

  }

  const findPosition = (curPos) => {
    const names = Object.keys(menuItems);
    const values = Object.values(menuItems);
    let result = null;
    for (let i = 0; i < names.length; i++){
       //console.log(i); 
      if (result !== null){
        continue;
      }

      //
      if (i === 5){
        if (curPos >= values[i-1]+500){
          result = names[i];
        }
      }else{
        if (curPos >= values[i] && curPos + 10 < values[i+1]){
          result = names[i];
        }
      }
      
    }

    return result;

  }

  const handleScroll = () => {
    getAnchorPoints();
    const curPos = window.scrollY + 80;
    setScroll(curPos);
    let curSection = findPosition(curPos);
    setActiveItem(curSection);
    setScroll(menuItems[curSection]);
    for(const section in menuItems){
      if (section === "filterID"){

      }else if(section === "hakkindaID"){

      }else if(section === "makalelerID"){

      }else if(section === "egitimlerID"){

      }else if(section === "bizkimizID"){

      }else if(section === "iletisimID"){

      }else{

      }
      

      const bottom = window.scrollHeight - window.scrollTop === window.clientHeight;
      //console.log(bottom);
      if (bottom){
        curSection = "iletisimID";  
      }else{
  
      }
        if (curPos >= menuItems["bizkimizID"] + 400){
          curSection = "iletisimID"
        }else{
          curSection = menuItems[section] <= curPos ? section : curSection;      
        }



      if(curSection !== section){
        break;
      }
    }
    
    if (curSection !== activeItem){
      setActiveItem(curSection);
      setScroll(menuItems[curSection]);
    }

    //console.log(menuItems);
  }


  const menuList = Object.keys(menuItems).map((e, i) => 
    <MenuItem itemName={e} key={`menuitem_${i}`} active={e === activeItem ? true : false} itemCaption={menuItemsCaption[e]}  />
  )  

  return(
    <ul className="bottomMenu">
      {menuList}
    </ul>
  );
}

export default Menu;