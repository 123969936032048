import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { ProjectActionTypes } from '../../../redux/project/project.types';

import { BrowserRouter as Router, Switch, Route, Redirect,Link,useHistory  } from 'react-router-dom';
import './articles.styles.scss';

const Articles = () => {
  const xprops = useSelector(state => state);
  const dispatch = useDispatch();

  const [articles, setArticles] = useState(false);
  const [latestBlogPosts, setLatestBlogPosts] = useState([]);
  const baseUrl = "https://www.sessizolmaz.org/";
  let history = useHistory();
  const goToArticle = (url) => {
    if (url === ""){
      return history.push('/blog');    
    }else{
      return history.push('/blog/'+url);    
    }
  }

  const goToUrl = (url) => {
    window.location = url;
  }
  


  useEffect(() => {
    setLatestBlogPosts(xprops.project.latestPosts);
  }, [])



  return(
    <React.Fragment>
      <div id="makalelerIDWrapper">
        <div id="makalelerID" className="articlesSectionWrapper">
          <div className="articlesBGWrapper">
            <h2 className="webSectionTitle">
              <img className="sessizOlmazLogo" src={process.env.PUBLIC_URL + '/assets/images/soso-blog-logo.png'} alt="" />
            </h2>
            <div className="articlesWrapper">
            {
              latestBlogPosts.length > 0 ? 
                <React.Fragment>
                  <div className="articleBox articleBox1">
                    <h4 className="articleTitle">{latestBlogPosts[0]["title"]}</h4>
                    <span className="articleWriter">{latestBlogPosts[0]["writer"]}</span>
                    <p className="articleAbstract" title={latestBlogPosts[0]["summary"]}>{latestBlogPosts[0]["summary"]}</p>
                    <a class="article-link" rel="noopener noreferrer" href={baseUrl+latestBlogPosts[0]["url"]}>Daha Fazla Göster</a>
                  </div>
                  <div className="articleBox articleBox2">
                    <h4 className="articleTitle">{latestBlogPosts[1]["title"]}</h4>
                    <span className="articleWriter">{latestBlogPosts[1]["writer"]}</span>
                    <p className="articleAbstract" title={latestBlogPosts[1]["summary"]}>{latestBlogPosts[1]["summary"]}</p>
                    <a  class="article-link" rel="noopener noreferrer" href={baseUrl+latestBlogPosts[1]["url"]}>Daha Fazla Göster</a>

                  </div>
                  <div className="articleBox articleBox3">
                    <h4 className="articleTitle">{latestBlogPosts[2]["title"]}</h4>
                    <span className="articleWriter">{latestBlogPosts[2]["writer"]}</span>
                    <p className="articleAbstract" title={latestBlogPosts[2]["summary"]}>{latestBlogPosts[2]["summary"]}</p>
                    <a  class="article-link" rel="noopener noreferrer" href={baseUrl+latestBlogPosts[2]["url"]}>Daha Fazla Göster</a>
                  </div>
                </React.Fragment>
              : ""
            
            
            }
            
            </div>
    
          </div>
          
        </div>
      </div>
    </React.Fragment>
  );
}

export default Articles;
