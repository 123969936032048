import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import NavBarSub from '../../components/navbarsub/navbarsub.component';
import Accordion from '../../components/accordion/accordion.component';

import './faq.page.styles.scss';

const FAQ = () => {

  const questionsAnswers = [
    {
      question: "Sensiz Olmaz Sessiz Olmaz nedir?",
      answer:"Bana Kadınlar Anlatsın, medyada kadın uzman görünürlüğünü arttırmayı amaçlayan bir proje. Sensiz Olmaz Sessiz Olmaz websitesi kadın uzmanları bir portal (www.sensizolmazsessizolmaz.org) altında bir araya getirerek, medyanın ve diğer kamusal mecraların kullanabileceği dijital bir veri tabanı oluşturmayı hedefliyor. Proje, aynı zamanda, kadın uzmanlara yönelik eğitim çalışmaları içeriyor ve toplumsal cinsiyet ve kadın çalışmaları alanlarına yönelik içerik sağlamayı hedefliyor."
    },
    {
      question: "Sensiz Olmaz Sessiz Olmaz kim(ler) tarafından yürütülen bir proje?",
      answer:"Sensiz Olmaz Sessiz Olmaz websitesi ve Bana Kadınlar Anlatsın projesi sivil toplum ve medya sektörlerinde uzun yıllardır çalışan Ayşe Karabat, Ayşe Yırcalı, Özge Genç Çakar, Sabiha Senyücel Gündoğar ve Semin Gümüşel Güner tarafından Ağustos 2020’de hayata geçirildi. Berfin Coşkun, Şubat 2021’de proje koordinatörü olarak ekibe katıldı. Proje ekibi hakkında detaylı bilgiye Biz Kimiz bağlantısından ulaşabilirsiniz."
    },
    {
      question: "Veri tabanına nasıl kaydolabilirim?",
      answer: `www.sensizolmazsessizolmag.org adresi üzerinden “Katılım” butonuna tıklayarak veya kayit.sensizolmazsessizolmaz.org adresinden direkt olarak katılımcı bilgi formuna erişebilirsiniz. Kendi rızanız ve onayınız ile ad, soyad, fotoğraf, iletişim bilgileri, uzmanlık alanı, özgeçmiş, kendi yazdığınız makaleleriniz ve bunlara ilişkin bağlantılar gibi kişisel bilgilerinizi içeren profilinizi rahatlıkla oluşturabilirsiniz.`
    },
    {
      question: "Herhangi bir medya çalışanı tarafından ulaşılmak istendiğimde süreç nasıl işleyecek?",
      answer: `Katılımcı Bilgi Formunda seçeceğiniz iletişim yöntemine göre (SMS, WhatsApp, arama veya e-mail) portal üzerinden size ulaşılabilecek. E-mail bilginizi paylaşmayı seçerseniz sizinle e-mail üzerinden, telefon numaranızı paylaşmayı seçerseniz sizinle telefon üzerinden iletişim kurulacak. İletişim bilgilerinizin profilinizde görülmesini istemediğiniz takdirde, bu bilgiler hiçbir şekilde profilinizde görülemeyecek fakat ilgili kişi telefon numaranızı görmeden sizi arayabilecek, SMS veya WhatsApp iletisi gönderebilecek; e-mailler ise yine bizim platformumuz üzerinden size yönlendirilecek. İzin vermemeniz halinde doldurmuş olduğunuz formda yer alan iletişim bilgileriniz, sitede arama yapanlar tarafından görülemeyecek.`
    },
    {
      question: "Kişisel bilgilerimin güvenliği nasıl sağlanacak?",
      answer: `Kişisel bilgilerinizin paylaşılması için izin vermeniz gerekiyor. Sağladığınız bilgiler sizin izniniz ve bilginiz dâhilinde diğer site kullanıcıları ile paylaşılır. İzin vermediğiniz hiçbir kişisel bilginizin paylaşılması söz konusu değildir. Bilgileriniz 6698 sayılı Kişisel Verilerin Korunması Kanunu kapsamında korunmaktadır. Detaylı açıklamayı içeren aydınlatma metnine <a href="https://www.sensizolmazsessizolmaz.org/soso-aydinlatma-metni.pdf" target="_blank" rel="noreferrer" class="referrerlink">www.sensizolmazsessizolmaz.org/soso-aydinlatma-metni.pdf</a> adresinden erişebilirsiniz. Kişisel verilerin işlenmesi ve korunmasına ilişkin politikamıza <a href="https://www.sensizolmazsessizolmaz.org/politika.pdf" target="_blank" rel="noreferrer" class="referrerlink">www.sensizolmazsessizolmaz.org/politika.pdf</a>`,
    },
    {
      question: "Kişisel bilgilerimin iznim dışında kullanıldığını fark edince ne yapmalıyım? ",
      answer: `Kişisel bilgilerininiz izniniz olmadan kullanılması gibi sayılı Kişisel Verilerin Korunması Kanunun 11. Maddesine aykırı düşen herhangi bir aktiviteyi fark ettiğinizde (<a href="https://www.sensizolmazsessizolmaz.org/basvuruformu.pdf" target="_blank" rel="noreferrer" class="referrerlink">www.sensizolmazsessizolmaz.org/basvuruformu.pdf</a>) sitesinde yer alan KVKK başvuru formunu doldurarak tarafımıza iletebilirsiniz. Söz konusu talepleriniz en geç 30 gün içinde sonuçlandırılarak tarafınıza bildirimde bulunulacaktır.`
    },
    {
      question: "Bize nasıl ulaşabilirsiniz?",
      answer: `Herhangi bir sorunuz olması veya bir sorunla karşılaşmanız halinde info@sensizolmazsessizolmaz.org adresinden bizimle iletişime geçebilirsiniz. Mesajınız proje ekibi tarafından en kısa sürede yanıtlanacaktır.`
    }    

  ];

  return(
    <React.Fragment>
      <div className="faqSectionWrapper">
        <NavBarSub />
        <div className="faqWrapper">
          <div className="faqList">
            <div className="faqListSticky">
              <h2 className="webSectionTitle">Sık Sorulan Sorular</h2>
            </div>
          </div>
          <div className="faqContent">
            <Accordion questionsAnswers={questionsAnswers} />        
          </div>
        </div>
        
        
      </div>
    </React.Fragment>
  );
}

export default FAQ;