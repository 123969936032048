import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { ProjectActionTypes } from '../../redux/project/project.types';
import Header from '../../components/home/header/header.component';
import Slider from '../../components/home/slider/slider.component';
import Filter from '../../components/home/filter/filter.component';
import Articles from '../../components/home/articles/articles.component';
import Educations from '../../components/home/educations/educations.component';
import About from '../../components/home/about/about.component';
import Contact from '../../components/home/contact/contact.component';
import BottomLayer from '../../components/home/bottomlayer/bottomlayer.component';
import Footer from '../../components/home/footer/footer.component';
import AboutLayer from '../../components/home/aboutlayer/aboutlayer.component';

import './home.page.styles.scss';
import { setScroll } from '../../redux/project/project.actions';

const Home = () => {
  const xprops = useSelector(state => state);
  const dispatch = useDispatch();
  const [mounted, setMounted] = useState(false);
  const setParent = (value) => dispatch({type: ProjectActionTypes.SET_PARENT, value: value });  


  if(!mounted){
    setParent("home");  
  }

  useEffect(() =>{
    setMounted(true);
    window.scrollTo(0,xprops.project.scrollPos);
    setScroll(xprops.project.scrollPos);
  },[]);  

    return(
      <React.Fragment>
        <div className="homeWrapper">
          <Header />
          <Slider />
          <Filter />
          <AboutLayer />
          <Articles />        
          <Educations />
          <About />
          <BottomLayer />
          
        </div>
      </React.Fragment>
    );
};

export default Home;