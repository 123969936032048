import React from 'react';
import { useHistory } from "react-router-dom";
import './slider.styles.scss';

const Slider = () => {

  let history = useHistory();
  const handleWatch = () => {
    history.push("/tanitim-filmi");
  }


  return (
    <React.Fragment>
      <div className="sliderID">
        <div className="sliderWrapper">
          <button className="btnWatchVideo" onClick={handleWatch}>TANITIM FİLMİNİ İZLEYİN <img className="playIcon" src={process.env.PUBLIC_URL + '/assets/images/play-icon.png'} alt="TANITIM FİLMİNİ İZLEYİN" /></button>
          <div className="sliderQuoteWrapper">
            <h2 className="sliderQuote" title="Sen ol ki">SEN OL Kİ</h2>
            <h2 className="sliderQuote" title="medyanın">MEDYANIN</h2>
            <h2 className="sliderQuote" title="sesi tam olsun">SESİ TAM OLSUN</h2>
          </div>        
        </div>
      </div>
    </React.Fragment>
  );
}

export default Slider;