import React from 'react';
import './radiobutton.styles.scss';

const RadioButton = ({name,value,defaultCheck,checked,onClick,children}) => (

  <label className="radioContainer">{children}
    <input type="radio" name={name} defaultChecked={defaultCheck} value={value} checked={checked} onClick={onClick}  />
    <span className="checkmarkRadio"></span>
  </label>  

);

export default RadioButton;