import React from 'react';
import './aboutlayer.styles.scss';

const AboutLayer = () => {
  return(
    <React.Fragment>
      <div id="hakkindIDWrapper">
        <div id="hakkindaID" className="aboutlayerSectionWrapper">
          <h2 className="webSectionTitle">PLATFORM HAKKINDA</h2>
          <div className="aboutlayerRow aboutlayerSecondRow">
            <div className="aboutlayerGridWrapper">
              <div className="aboutlayer">
                {
                //<h4 className="aboutlayerTitle">“Bana Kadınlar Anlatsın” çalışmamızda medya eğitimleri düzenleyeceğiz.</h4>
                // <span className="educationDate">5 Ocak 2021 14:00</span>
                // <span className="educationLocation">Beşiktaş, İstanbul</span>
                }
                <p className="aboutlayerContent">
                Türkiye’de kadınların medyada ve kamusal alanda uzman, yazar, konuşmacı ve tartışmacı olarak görünürlüğü kabul edilemeyecek kadar düşük. Erkek uzmanlar orantısız şekilde bu mecralarda daha fazla temsil ediliyor.
                </p>
                <p className="aboutlayerContent">
                Oysa, Türkiye’de kadın erkek sayısı neredeyse eşit... Kadınlar iş dünyasında, sivil toplumda, üniversitelerde, kamuda, her alanda varlar. Buna rağmen kamusal alanda hak ettikleri seviyede görünmüyor ve duyulmuyorlar.
                </p>
                <p className="aboutlayerContent">
                <strong>"Bana Kadınlar Anlatsın: Türkiye'de Kadınların Medyada Görünürlüğünü İyileştirme"</strong> projesi kadınların medyada uzman ve katkı sunan bireyler olarak görünürlüğünü artırmak amacıyla yola çıktı.
                </p>              
              </div>
              <div className="aboutlayer">
                {
                //<h4 className="aboutlayerTitle">Eğitimlerimiz yakında başlayacak.</h4>
                // <span className="educationDate">25 Aralık 2020 19:00</span>
                // <span className="educationLocation">Online</span>
                }
                <p className="aboutlayerContent">"Sensiz Olmaz Sessiz Olmaz" platformu, televizyon, gazete, çevrimiçi haber sitelerinin yanı sıra sivil toplum ve özel sektör toplantılarında da kadın uzmanlara dengeli bir şekilde yer verilmesini sağlamak üzere (1) farklı disiplinlerden kadın uzmanların profillerinden oluşan bir veri tabanı sunuyor; (2) sürekli olarak güncellenen bu veri tabanı ile editör, gazeteci, televizyon yapımcısı, organizatör ve ilgili tüm paydaşların alanlarında uzman kadınlara kolaylıkla erişebilmesini sağlıyor. </p>
                <p className="aboutlayerContent">Çok yakında her alandan birçok kadın uzmana ulaşabileceksiniz.</p>
                <p className="aboutlayerContent"><strong>"Sensiz Olmaz Sessiz Olmaz"</strong> platformu ve “Bana Kadınlar Anlatsın” projesi kâr amacı gütmeyen bir sosyal girişim olarak faaliyet gösteriyor.</p>
              </div>

            </div>
          </div>

        </div>
      </div>
    </React.Fragment>
  );
}

export default AboutLayer;